import React,{useState, useEffect} from "react";
import Modal from "@salesforce/design-system-react/components/modal";
import formBuilderScreenStrings from "localizations/formBuilderScreen";
import Button from '@salesforce/design-system-react/components/button';
import Combobox from '@salesforce/design-system-react/components/combobox';
import comboboxFilterAndLimit from '@salesforce/design-system-react/components/combobox/filter';
import { CQFSchemaBuilder } from '../schemaBuilder';
import strings from "localizations/formScreen";
import Spinner from '@salesforce/design-system-react/components/spinner';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Tooltip from '@salesforce/design-system-react/components/tooltip';
import { CQFormBuilderConstants } from "../formbuilder-constants";
import { fetchSObjects } from "../CQFetchSObject";


// incoming props for Select Object modal
interface CQFormBuilderModalProps {
    openModal: boolean;
    selectObjectState: any;
    contextObjectState:any;
    contextObjectList:any;
    handleCloseModal: () => void;
    navigateToFormBuilder: (selectObjectState) => void;
    handleSelectObjectChange: (value) => void;
    handleSelectObjectSelection: (data, mainObjects:any) => void;
    handleSelectObjectRemoveSelection: (data) => void;
    handleContextObjectChange:(value) => void;
    handleContextObjectSelection: (data) => void;
    handleContextObjectRemoveSelection: (data) => void;
}

export default function CQFormBuilderSelectObjectModal({
    openModal,
    selectObjectState,
    contextObjectState,
    contextObjectList,
    handleCloseModal,
    navigateToFormBuilder,
    handleSelectObjectChange,
    handleSelectObjectSelection,
    handleSelectObjectRemoveSelection,
    handleContextObjectChange,
    handleContextObjectSelection,
    handleContextObjectRemoveSelection

}: CQFormBuilderModalProps) {

    const [editFormObject, setEditFormObject] = useState<Array<object>>([]);
    const [objectSelected, setObjectSelected] = useState(false);

    /**
     * Get objects in org for showing to select object for building Form
     */
    const getObjectsForSelectObjectModal = async() =>{
        const object = await fetchSObjects();
        setEditFormObject(object);
    }


    useEffect(() => {
        getObjectsForSelectObjectModal();
    }, []);

  return (
    <IconSettings iconPath="/assets/icons">
        <Modal
            isOpen = {openModal}
            dismissOnClickOutside={false}
            onRequestClose={handleCloseModal}
            title={formBuilderScreenStrings.selectObject}
            ariaHideApp={false}
            footer={[
                <Button label={formBuilderScreenStrings.selectObjectNext} variant="brand"  onClick={() => navigateToFormBuilder(selectObjectState)}/>
            ]}
            >
                { selectObjectState!.selection!.length === 0 && objectSelected ?
                    <Spinner
                        size="medium"
                        variant="base"
                        assistiveText={{ label: strings.loading }}
                    /> : null
                }
            <section className="cq-object-modal-section">
                <div style={{height: '12rem'}}>
                    <section className="cq-main-object-label-section">
                        <label>{formBuilderScreenStrings.mappedFieldObject}</label>
                        <Tooltip
                            id="mainBase"
                            align="top"
                            content={formBuilderScreenStrings.mainObjectHelpText}
                            variant="learnMore"
                            dialogClassName="dialog-box"
                        />
                    </section>
                    <Combobox
                        menuPosition="relative"
                        id="combobox-inline-single"
                        events={{
                            onChange: (event, { value }) => {
                                handleSelectObjectChange(value)
                            },
                            onRequestRemoveSelectedOption: (event, data) => {
                                setObjectSelected(false);
                                handleSelectObjectRemoveSelection(data)
                            },
                            onSelect: (event, data) => {
                                setObjectSelected(true);
                                handleSelectObjectSelection(data, editFormObject);
                            },
                        }}
                        labels={{
                            placeholder: formBuilderScreenStrings.searchObjectPlaceholder
                        }}
                        options={comboboxFilterAndLimit({
                            inputValue: selectObjectState.inputValue,
                            options: editFormObject,
                            limit: editFormObject.length,
                            selection: selectObjectState.selection,
                        })}
                        selection={selectObjectState.selection}
                        value={selectObjectState.inputValue}
                        variant="inline-listbox"
                    />
                    <div className="cq-context-object-combobox">
                        <section className="cq-context-object-label-section">
                            <label>{formBuilderScreenStrings.contextFieldObject}</label>
                            <Tooltip
                                id="contextBase"
                                align="top"
                                content={formBuilderScreenStrings.contextHelpText}
                                variant="learnMore"
                                dialogClassName="dialog-box"
                            />
                        </section>
                        <Combobox
                            menuPosition="relative"
                            id="combobox-inline-single-context"
                            events={{
                                onChange: (event, { value }) => {
                                    handleContextObjectChange(value)
                                },
                                onRequestRemoveSelectedOption: (event, data) => {
                                    handleContextObjectRemoveSelection(data)
                                },
                                onSelect: (event, data) => {
                                    handleContextObjectSelection(data)
                                },
                            }}
                            labels={{
                                placeholder: formBuilderScreenStrings.searchObjectPlaceholder,
                            }}
                            options={comboboxFilterAndLimit({
                                inputValue: contextObjectState.inputValue,
                                options: contextObjectList,
                                limit: contextObjectList.length,
                                selection: contextObjectState.selection,
                            })}
                            selection={contextObjectState.selection}
                            value={contextObjectState.inputValue}
                            variant={objectSelected ? "inline-listbox": "readonly"}
                        />
                    </div>
                </div>
            </section>
        </Modal>
    </IconSettings>
  );
}
