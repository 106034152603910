import {CQFormLayout} from './CQFormLayout';


export class CQFormBuilderConstants{
    public static selectedObjectSchema(objectType: string) {
        return {
            "objectType": objectType,
            "fields": [],
            "child": {
                "objectType": "cqext__SQX_Safety_Inspection_Criteria__c",
                "fields": [
                    "cqext__criteria_title__c"
                ],
                "relationshipName": "cqext__SQX_Safety_Inspection_Criteria__r",
                "child": {
                    "objectType": "cqext__SQX_Safety_Checklist__c",
                    "fields": [
                        "cqext__response_type__c",
                        "cqext__response_value__c",
                        "cqext__response_score__c",
                        "cqext__comment__c"
                    ],
                    "relationshipName": "cqext__SQX_Safety_Checklist__r"
                }
            }
        };
    }

    public static safetyInspectionQuery(droppedField: any) {
        return {
            "mappingData": {
                "cqext__SQX_Safety_Inspection__c": {
                    "cqext__SQX_Safety_Inspection_Criteria__r": {
                        "cqext__SQX_Safety_Inspection_Criteria__c": {
                            "cqext__SQX_Safety_Checklist__r": {
                                "cqext__SQX_Safety_Checklist__c": {
                                    "cqext__Response_Type__c": "$currentRecord.cqext__SQX_Response_Type__c",
                                    "cqext__Response_label__c": "$currentRecord.cqext__Question_Title__c",
                                    "cqext__Question_Type__c": "$currentRecord.cqext__Question_Type__c",
                                    "cqext__Comment__c": "$currentRecord.cqext__Comment__c",
                                    "cqext__Weight__c": "$currentRecord.cqext__Weight__c"
                                },
                                "source": "$context.cqext__SQX_Questions__r"
                            },
                            "cqext__Criteria_Title__c": "$context.cqext__Section_Title__c"
                        },
                        "source": "$context.cqext__SQX_Sections__r"
                    },
                    "attributes": {
                        "type": "cqext__SQX_Safety_Inspection__c"
                    },
                    "cqext__Inspection_Type__c": "$context.cqext__Inspection_Type__c",
                    "cqext__SQX_Checklist__c": "$documentname",
                    "cqext__Inspection_Title__c": "$context.cqext__Inspection_Title__c",
                    "cqext__Start_Date__c": "$context.cqext__Start_Date__c",
                    "cqext__End_Date__c": "$context.cqext__End_Date__c",
                    "cqext__SQX_Location__c": "$context.cqext__SQX_Location__c",
                    "cqext__Assignee_Type__c": "User",
                    "cqext__SQX_Assignee__c": "$currentuser.Name"
                }
            },
            "relationships": [
                {
                    "relationships": [
                        {
                            "orderByDirection": "ASC",
                            "orderbyFields": [
                                "cqext__Order_Score__c"
                            ],
                            "connectionField": "cqext__SQX_Section__c",
                            "fields": [
                                "cqext__Question_Title__c",
                                "cqext__Question_Type__c",
                                "cqext__SQX_Response_Type__c",
                                "cqext__Analysis_Code__c",
                                "cqext__Order_Score__c",
                                "cqext__Weight__c"
                            ],
                            "objectType": "cqext__SQX_Question__c"
                        }
                    ],
                    "orderByDirection": "ASC",
                    "orderbyFields": [
                        "Name",
                        "cqext__Section_Title__c"
                    ],
                    "connectionField": "cqext__SQX_Controlled_Document__c",
                    "fields": [
                        "Id",
                        "cqext__Section_Title__c"
                    ],
                    "objectType": "cqext__SQX_Section__c"
                }
            ],
            "orderByDirection": "ASC",
            "orderByFields": [
                "Name"
            ],
            "filter": {
                "field": "Name",
                "operator": "eq",
                "s_value": droppedField
            },
            "fields": [
                "Id",
                "Name"
            ],
            "objectType": "compliancequest__SQX_Controlled_Document__c",
            "type": "DOCUMENTS",
            "key": droppedField
        };
    }

    public static nonSafetyInspectionQuery(droppedField: any, selectedObjectApiName: string) {
        return {
            "mappingData": {
                [selectedObjectApiName]: {
                    "cqext__SQX_Safety_Inspection_Criteria__r": {
                        "cqext__SQX_Safety_Inspection_Criteria__c": {
                            "cqext__SQX_Safety_Checklist__r": {
                                "cqext__SQX_Safety_Checklist__c": {
                                    "cqext__Response_Type__c": "$currentRecord.cqext__SQX_Response_Type__c",
                                    "cqext__Response_label__c": "$currentRecord.cqext__Question_Title__c",
                                    "cqext__Question_Type__c": "$currentRecord.cqext__Question_Type__c",
                                    "cqext__Comment__c": "$currentRecord.cqext__Comment__c",
                                    "cqext__Weight__c": "$currentRecord.cqext__Weight__c"
                                },
                                "source": "$context.cqext__SQX_Questions__r"
                            },
                            "cqext__Criteria_Title__c": "$context.cqext__Section_Title__c"
                        },
                        "source": "$context.cqext__SQX_Sections__r"
                    },
                    "attributes": {
                        "type": selectedObjectApiName
                    }
                }
            },
            "relationships": [
                {
                    "relationships": [
                        {
                            "orderByDirection": "ASC",
                            "orderbyFields": [
                                "cqext__Order_Score__c"
                            ],
                            "connectionField": "cqext__SQX_Section__c",
                            "fields": [
                                "cqext__Question_Title__c",
                                "cqext__Question_Type__c",
                                "cqext__SQX_Response_Type__c",
                                "cqext__Analysis_Code__c",
                                "cqext__Order_Score__c",
                                "cqext__Weight__c"
                            ],
                            "objectType": "cqext__SQX_Question__c"
                        }
                    ],
                    "orderByDirection": "ASC",
                    "orderbyFields": [
                        "Name",
                        "cqext__Section_Title__c"
                    ],
                    "connectionField": "cqext__SQX_Controlled_Document__c",
                    "fields": [
                        "Id",
                        "cqext__Section_Title__c"
                    ],
                    "objectType": "cqext__SQX_Section__c"
                }
            ],
            "orderByDirection": "ASC",
            "orderByFields": [
                "Name"
            ],
            "filter": {
                "field": "Name",
                "operator": "eq",
                "s_value": droppedField
            },
            "fields": [
                "Id",
                "Name"
            ],
            "objectType": "compliancequest__SQX_Controlled_Document__c",
            "type": "DOCUMENTS",
            "key": droppedField
        };
    }
    public static selectedObjectUiSchema(selectedObj : string) {
        return new CQFormLayout({
        "type": "VerticalLayout",
        "elements": [
            {
                "label": "Section Header Title Area",
                "type": "Group",
                "elements": [
                    {
                        "type": "HorizontalLayout",
                        "elements": [
                        ]
                    }
                ]
            },
            {
                "layout": {
                    "type": "ListWithDetail",
                    "scope": "#/properties/cqext__SQX_Safety_Inspection_Criteria__c/properties/cqext__SQX_Safety_Checklist__r",
                    "title": "Questions",
                    "layout": {
                        "type": "VerticalLayout",
                        "elements": [
                            {
                                "elements": [
                                    {
                                        "type": "label",
                                        "scope": "#/properties/cqext__SQX_Safety_Checklist__c/properties/cqext__Response_label__c",
                                        "options": {
                                            "style": "bold",
                                            "size": 8,
                                            "align": "left"
                                        }
                                    },
                                    {
                                        "elements":[
                                            {
                                                "type": "select",
                                                "scope": "#/properties/cqext__SQX_Safety_Checklist__c",
                                                "options": {
                                                    "size": 12,
                                                    "align": "right",
                                                    "valueScope": "cqext__Response_value__c",
                                                    "labelScope": "cqext__Response_value__c",
                                                    "field": "compliancequest__SQX_Result_Type__c",
                                                    "dependsOn": "cqext__Response_Type__c",
                                                    "lookupTo": "compliancequest__SQX_Result_Type_Value__c",
                                                    "valueField": "Name",
                                                    "titleField": "Name"
                                                }
                                            }
                                        ],
                                        "type": "CQFlexLayout"
                                    }
                                ],
                                "type": "HorizontalLayout"
                            },
                            {
                                "elements": [
                                    {
                                        "type": "Control",
                                        "scope": "#/properties/cqext__SQX_Safety_Checklist__c/properties/cqext__Comment__c",
                                        "options": {
                                            "style": "bold",
                                            "size": 8,
                                            "align": "left",
                                            "multi": true
                                        }
                                    }
                                ],
                                "type": "HorizontalLayout"
                            }
                        ]
                    },
                    "footerLayout": {
                        "type": "HorizontalLayout",
                        "elements": [
                            {
                                "type": "fileUpload",
                                "scope": "#/properties/cqext__SQX_Safety_Checklist__c/properties/Files"
                            }
                        ]
                    }
                },
                "section": 1,
                "scope": `#/properties/${selectedObj}/properties/cqext__SQX_Safety_Inspection_Criteria__r`,
                "title": "Sections",
                "type": "Section",
                "options": {
                    "droptype": "DOCUMENTS",
                    "headerLayout": {
                        "type": "label",
                        "scope": "#/properties/cqext__SQX_Safety_Inspection_Criteria__c/properties/cqext__Criteria_Title__c"
                    }
                }
            }, 
            {
                "type": "tableEditor",
                "options": {
                    "visible": true
                }
            }
        ]
    })};

    public static safetyInspectionUItemplate(selectedObj : any )  {
        return {
            "type": "VerticalLayout",
            "elements": [
                {
                    "label": "Section Header Title Area",
                    "type": "Group",
                    "elements": [
                        {
                            "type": "HorizontalLayout",
                            "elements": [
                            ]
                        }
                    ]
                },
                {
                    "layout": {
                        "type": "ListWithDetail",
                        "scope": "#/properties/cqext__SQX_Safety_Inspection_Criteria__c/properties/cqext__SQX_Safety_Checklist__r",
                        "title": "Questions",
                        "layout": {
                            "type": "VerticalLayout",
                            "elements": [
                                {
                                    "elements": [
                                        {
                                            "type": "label",
                                            "scope": "#/properties/cqext__SQX_Safety_Checklist__c/properties/cqext__Response_label__c",
                                            "options": {
                                                "style": "bold",
                                                "size": 8,
                                                "align": "left"
                                            }
                                        },
                                        {
                                            "elements":[
                                                {
                                                    "type": "select",
                                                    "scope": "#/properties/cqext__SQX_Safety_Checklist__c",
                                                    "options": {
                                                        "size": 12,
                                                        "align": "right",
                                                        "valueScope": "cqext__Response_value__c",
                                                        "labelScope": "cqext__Response_value__c",
                                                        "field": "compliancequest__SQX_Result_Type__c",
                                                        "dependsOn": "cqext__Response_Type__c",
                                                        "lookupTo": "compliancequest__SQX_Result_Type_Value__c",
                                                        "valueField": "Name",
                                                        "titleField": "Name"
                                                    }
                                                }
                                            ],
                                            "type": "CQFlexLayout"
                                        }
                                    ],
                                    "type": "HorizontalLayout"
                                },
                                {
                                    "elements": [
                                        {
                                            "type": "Control",
                                            "scope": "#/properties/cqext__SQX_Safety_Checklist__c/properties/cqext__Comment__c",
                                            "options": {
                                                "style": "bold",
                                                "size": 8,
                                                "align": "left",
                                                "multi": true
                                            }
                                        }
                                    ],
                                    "type": "HorizontalLayout"
                                }
                            ]
                        },
                        "footerLayout": {
                            "type": "HorizontalLayout",
                            "elements": [
                                {
                                    "type": "fileUpload",
                                    "scope": "#/properties/cqext__SQX_Safety_Checklist__c/properties/Files"
                                }
                            ]
                        }
                    },
                    "section": 1,
                    "scope": `#/properties/${selectedObj}/properties/cqext__SQX_Safety_Inspection_Criteria__r`,
                    "title": "Sections",
                    "type": "Section",
                    "options": {
                        "droptype": "DOCUMENTS",
                        "headerLayout": {
                            "type": "label",
                            "scope": "#/properties/cqext__SQX_Safety_Inspection_Criteria__c/properties/cqext__Criteria_Title__c"
                        }
                    }
                }, 
                {
                    "type": "tableEditor",
                    "options": {
                        "visible": true
                    }
                }
            ]
        }
    }

    public static NAMESPACE_REGEX = /^(compliancequest__|cqext__)/;
    public static OBJECT_NAME_REGEX = /[^_]+(?=__c$)/;
}

