import React from 'react';
import GlobalHeader from '@salesforce/design-system-react/components/global-header'; 
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import SLDSGlobalHeaderHelp from '@salesforce/design-system-react/components/global-header/help';
import SLDSGlobalHeaderNotifications from '@salesforce/design-system-react/components/global-header/notifications';
import SLDSGlobalHeaderProfile from '@salesforce/design-system-react/components/global-header/profile';
import SLDSGlobalHeaderButton from '@salesforce/design-system-react/components/global-header/button';
import SLDSPopover from '@salesforce/design-system-react/components/popover';
import SLDSAvatar from '@salesforce/design-system-react/components/avatar';
import Icon from '@salesforce/design-system-react/components/icon'; 
import strings from '../../localizations/homeScreen';
import {Link} from 'react-router-dom';
import CQHeaderProfileContent from './CQHeaderProfileContent';
import { isMobileOnly } from 'react-device-detect';
const GlobalHeaderHelp : any = SLDSGlobalHeaderHelp;
const GlobalHeaderNotifications : any = SLDSGlobalHeaderNotifications;
const GlobalHeaderProfile : any = SLDSGlobalHeaderProfile;
const Popover : any = SLDSPopover;
const Avatar : any = SLDSAvatar;

function CQGlobalHeader() {

    const userDetailsStr = localStorage.getItem('userDetails');
    var userDetails : any;
    if(userDetailsStr !== null && userDetailsStr !== "undefined"){
        userDetails = JSON.parse(userDetailsStr);
    }
  
    return (<>
    {isMobileOnly ? 
        <div className='cq-form-mbl-header'>
        {userDetails != null ? 
            <h1 className='cq-form-mbl-title'>Welcome Back, {userDetails.Name}</h1>:<h1>Welcome back</h1>    
        }
        <div className='cq-form-mbl-icongroup'>
            {/* Other GlobalHeader components */}
            <IconSettings iconPath="/assets/icons">
                <Icon
                  category="utility"
                  name="notification"
                  size="small"
                  style={{fill : '#0070d2'}}
                />
            </IconSettings>
            {userDetails != null ? 
                <GlobalHeaderProfile
                    avatar={
                        <div className='cq-form-user-icon'>
                            <IconSettings iconPath="/assets/icons">
                                <Icon 
                                    style={{height: '15px', fill : '#ffffff'}}
                                    category="utility"
                                    name="user"
                                    size="small"
                                />
                            </IconSettings>
                        </div>

                       }
                    popover={
                        <Popover
                            heading=''
                            body={<CQHeaderProfileContent userDetails={userDetails} />}
                            id="header-profile-popover-id"
                        />
                    }
                />:
                    <IconSettings iconPath="/assets/icons">
                         <Link to="/login">
                            <Icon
                                assistiveText={{ label: strings.offline }}
                                category="utility"
                                name="offline"
                                size="small"
                                style={{fill : '#0070d2'}}
                            />
                        </Link>
                    </IconSettings>
                }
        </div>        
    </div> : 
            <IconSettings iconPath="/assets/icons">
            <GlobalHeader
              logoSrc="/assets/images/logo.png"
              onSkipToContent={() => {
                console.log('>>> Skip to Content Clicked');
              }}
              onSkipToNav={() => {
                console.log('>>> Skip to Nav Clicked');
              }}
            >
            {/* <GlobalHeaderSearch
                combobox={
                    <Combobox
                        assistiveText={{ label: strings.search }}
                        events={{
                            onSelect: () => {
                                console.log('>>> onSelect');
                            },
                        }}
                        id="header-search-custom-id"
                        labels={{ placeholder: strings.search }}
                        options={[]}
                    />
                }
            /> */}
            {/* <GlobalHeaderFavorites
                actionSelected={true}
                onToggleActionSelected={(event, data) => {
                    //
                }}
                popover={
                    <Popover
                        ariaLabelledby="favorites-heading"
                        body={
                            <div>
                                <h2
                                    className="slds-text-heading_small"
                                    id="favorites-heading"
                                >
                                    Favorites
                                </h2>
                                Ipsum
                            </div>
                        }
                        id="header-favorites-popover-id"
                    />
                }
            /> */}
            {/* <GlobalHeaderTask
                dropdown={
                    <Dropdown
                        id="header-task-dropdown-id"
                        options={[
                            { id: 'taskOptionOne', label: 'Task Option One' },
                            { id: 'taskOptionTwo', label: 'Task Option Two' },
                        ]}
                    />
                }
            /> */}
            <GlobalHeaderHelp
                popover={
                    <Popover
                        ariaLabelledby="help-heading"
                        body={
                            <></>
                        }
                        id="header-help-popover-id"
                    />
                }
            />
            {/* <GlobalHeaderSetup
                dropdown={
                    <Dropdown
                        id="header-setup-dropdown-id"
                        options={[
                            { id: 'setupOptionOne', label: 'Setup Option One' },
                            { id: 'setupOptionTwo', label: 'Setup Option Two' },
                        ]}
                    />
                }
            /> */}
            <GlobalHeaderNotifications
                notificationCount={0}
                popover={
                    <Popover
                        ariaLabelledby="header-notifications-custom-popover-content"
                        body={
                            <></>
                        }
                        id="header-notifications-popover-id"
                    />
                }
            />
              {userDetails != null ? 
                  <GlobalHeaderProfile
                      avatar={
                          <Avatar
                            variant="user"
                            label={userDetails.Name}
                            size="small"
                          />}
                      popover={
                          <Popover
                              heading=''
                              body={<CQHeaderProfileContent userDetails={userDetails} />}
                              id="header-profile-popover-id"
                          />
                      }
                  />:
                  <SLDSGlobalHeaderButton>
                      <Link to="/login">
                        <Icon
                            assistiveText={{ label: strings.offline }}
                            category="utility"
                            name="offline"
                            size="small"
                        />
                      </Link>
                  </SLDSGlobalHeaderButton>
              }
  
            </GlobalHeader>
          </IconSettings>
    }
    </>);
}

export default CQGlobalHeader;
