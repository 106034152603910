import CQLookup from './CQLookup';
import CQLookupTester from './CQLookupTester';
import CQFileUpload from './CQFileUpload';
import CQFileUploadTester from './CQFileUploadTester';
import CQSubForm from './CQSubForm';
import CQSubFormTester from './CQSubFormTester';
import CQMaterialListItem from './CQMaterialListItem';
import CQMaterialListItemTester from './CQMaterialListItemTester';
import CQFileCard from './CQFileCard';
import CQFileCardTester from './CQFileCardTester';
import CQSignatures from './CQSignatures';
import CQSignaturesTester from './CQSignaturesTester';
import CQSignature from './CQSignature';
import CQSignatureTester from './CQSignatureTester';
import CQLabel from './CQLabel';
import CQLabelTester from './CQLabelTester';
import CQGrid from './CQGrid';
import { CQGridTester } from './CQGridTester';
import CQSection from './CQSection';
import { CQSectionTester } from './CQSectionTester';
import CQSelect from './CQSelect';
import CQSelectTester from './CQSelectTester';
import CQQuickLinks from './CQQuickLinks';
import CQQuickLinksTester from './CQQuickLinksTester';
import CQFlexLayout from './CQFlexLayout';
import CQFlexLayoutTester from './CQFlexLayoutTester';
import CQDate from './CQDate';
import CQDateTester from './CQDateTester';
import CQInput from './CQInput';
import CQInputTester from './CQInputTester';
import CQCheckbox from './CQCheckbox';
import CQCheckboxTester from './CQCheckboxTester';
import CQTabs from './CQTabs';
import CQTabsTester from './CQTabsTester';
import CQTab from './CQTab';
import CQTabTester from './CQTabTester';
import CQTextAreaTester from './CQTextAreaTester';
import CQTextArea from './CQTextArea';
import CQDateTimeTester from './CQDateTimeTester';
import CQDateTime from './CQDateTime';
import CQBlank from './CQBlank';
import CQBlankTester from './CQBlankTester';
//TODO: identify why CQGuide is outside the renderer
import CQGuide from '../components/CQGuide';
import CQGuideTester from './CQGuideTester';
import CQDependentPicklist from './CQDependentPicklist';
import CQDependentPicklistTester from './CQDependentPicklistTester';
import CQFieldsSection from './CQFieldsSection';
import CQFieldsSectionTester from './CQFieldsSectionTester';
import CQFieldsEditor from './editor/CQFieldsEditor';
import CQNumber from './CQNumberControl';
import CQNumberTester from './CQNumberControlTester';
import CQStatic from './CQStaticField';
import CQStaticTester from './CQStaticFieldTester';

import {EditableWrapper, NoDropWrapper} from './editor/Wrapper';

import {
    materialRenderers,
    materialCells
} from '@jsonforms/material-renderers';
import CQGenericFileUploadTester from './CQGenericFileUploadTester';
import CQGenericFileUpload from './CQGenericFileUpload';
import CQTableEditorTester from './CQTableEditorTester';
import CQTableEditor from './CQTableEditor';
import CQDynamicLayoutTester from './CQDynamicLayoutTester';
import CQDynamicLayout from './CQDynamicLayout';

export const Renderers = [...materialRenderers, 
        //register custom renderers
        { tester: CQGridTester, renderer: CQGrid },
        { tester: CQBlankTester, renderer: CQBlank},
        { tester: CQSectionTester, renderer: CQSection},
        { tester: CQLookupTester, renderer: CQLookup },
        { tester: CQGuideTester,  renderer: CQGuide},
        { tester: CQFileUploadTester, renderer: CQFileUpload },
        { tester: CQSubFormTester, renderer: CQSubForm },
        { tester: CQMaterialListItemTester, renderer: CQMaterialListItem },
        { tester: CQFileCardTester, renderer: CQFileCard },
        { tester: CQSignatureTester, renderer: CQSignature },
        { tester: CQSignaturesTester, renderer: CQSignatures },
        {tester: CQGenericFileUploadTester, renderer: CQGenericFileUpload},
        { tester: CQLabelTester, renderer: CQLabel },
        { tester: CQSelectTester, renderer: CQSelect },
        { tester: CQQuickLinksTester, renderer: CQQuickLinks},
        { tester: CQFlexLayoutTester, renderer: CQFlexLayout},
        { tester: CQDateTester, renderer: CQDate},
        { tester: CQInputTester, renderer: CQInput},
        {tester: CQNumberTester, renderer: CQNumber},
        { tester: CQTabTester, renderer: CQTab},
        { tester: CQTabsTester, renderer: CQTabs},
        { tester: CQCheckboxTester, renderer: CQCheckbox},
        { tester: CQTextAreaTester,renderer: CQTextArea},
        { tester: CQDateTimeTester,renderer:CQDateTime},
        { tester: CQDependentPicklistTester,renderer:CQDependentPicklist},
        { tester: CQFieldsSectionTester, renderer: CQFieldsEditor},
        { tester: CQStaticTester, renderer: CQStatic},
        { tester: CQTableEditorTester, renderer: CQTableEditor},
        {tester: CQDynamicLayoutTester, renderer: CQDynamicLayout}
    ];

const schemasToTestFor = [
    {schema: {"type": "string", "description": "Anything"}, uischema: {"type": "blank", "scope": "#"}},
    {schema: {"type": "string", "description": "Anything"}, uischema: {"type": "static", "scope": "#"}},
    {schema: {"type": "integer", "description": "Anythinng"}, uischema: {"type": "Control", "scope": "#"}},
    {schema: {"type": "number", "description": "Anythinng"}, uischema: {"type": "Control", "scope": "#"}},
    {schema: {"type": "boolean", "description": "Anythinng"}, uischema: {"type": "Control", "scope": "#"}},
    {schema: {"type": "string", "description": "Anythinng"}, uischema: {"type": "Control", "scope": "#"}},
    {schema: {"type": "string", "description": "Anythinng", oneOf: []}, uischema: {"type": "Control", "scope": "#"}},
    {schema: {"type": "string", "format": "date", "description": "Anythinng"}, uischema: {"type": "Control", "scope": "#"}},
    {schema: {"type": "string", "description": "Anythinng"}, uischema: {"type": "lookup", "scope": "#"}},
    {schema: {"type": "string", "description": "Anythinng"}, uischema: {"type": "select", "scope": "#", "options":{"showLabel": true}}},
    {schema: {"type": "string", "format": "date-time", "description": "Anythinng"}, uischema: {"type": "Control", "scope": "#"}}
];

const schemasForReadonlyWrapper = [
    {schema: {"type": "string", "description": "Anythinng"}, uischema: {"type": "signatureCard", "scope": "#"}},
    {schema: {"type": "string", "description": "Anythinng"}, uischema: {"type": "fileUpload", "scope": "#"}},
    {schema: {"type": "array", "description": "Anythinng"}, uischema: {"type": "ListWithDetail", "scope": "#"}},
    {schema: {"type": "string", "description": "Anythinng"}, uischema: {"type": "genericfileupload", "scope": "#"}}
];

const schemasForNoDropZones = [
    {schema: {"type": "array", "description": "Anythinng"}, uischema: {"type": "Section", "scope": "#"}}
]


let editorRenderers = Renderers.map(renderer => {

    if(renderer.renderer === CQFieldsSection) {
        return {tester: renderer.tester, renderer : CQFieldsEditor};
    }

    let renderersToTest = [{ tests: schemasForReadonlyWrapper, isEnabled: false, component: EditableWrapper }
                            , { tests: schemasToTestFor, isEnabled: true, component: EditableWrapper }
                            , { tests: schemasForNoDropZones, isEnabled: true, component: NoDropWrapper } ];

    for(let rendersIndex = 0; rendersIndex < renderersToTest.length; rendersIndex++) {
        let schemas = renderersToTest[rendersIndex].tests;
        let isEnabled = renderersToTest[rendersIndex].isEnabled;
        for(let index = 0; index < schemas.length; index++) {
            let selectedSchema = schemas[index];
            if(renderer.tester(selectedSchema.uischema, selectedSchema.schema) !== -1) {
                console.log('Changed for ', selectedSchema);
                let component = renderersToTest[rendersIndex].component;
                return { tester: renderer.tester, renderer : component(renderer.renderer, isEnabled)};
            }
        }
    }
    return renderer;
});
export const EditorRenderers = [...editorRenderers]

export const Cells = materialCells;