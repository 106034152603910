import React, { useState } from 'react'
import Modal from "@salesforce/design-system-react/components/modal";
import formBuilderScreenStrings from "localizations/formBuilderScreen";
import Icon from "@salesforce/design-system-react/components/icon";

interface CQFormBuilderCheckInProps {
    openModal: boolean;
    closeModal: () => void;
    title: string;
    value: string;
    fieldLabel: string;
    handleChange: (evt) => void;
    handleSave: (comment:string, redirection : any) => void;
  }

export default function CQCheckInModal({
    handleChange,
    openModal,
    closeModal,
    fieldLabel,
    value,
    handleSave,
    title,

}: CQFormBuilderCheckInProps) { 
      
    const handleSaveModal = () => {
      handleSave(value, true);
    }
    const handleCloseModal = () => {
        closeModal();
      }
      return (
        <Modal
          className='cq-modal'
          dismissOnClickOutside={false}
          isOpen={openModal}
          onRequestClose={handleCloseModal}
          title={title}
          ariaHideApp={false}
        >
          <section className="slds-p-around_medium">
            <div>
              <p>
                  {fieldLabel}
              </p>
              <textarea
                  className="slds-p-around_xxx-small slds-input cq-check-in-modal-textarea"
                  value={value}
                  onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="slds-grid slds-m-top_large cq-check-in-modal-footer">
              <button
                className="slds-button slds-button_neutral"
                onClick={handleCloseModal}
              >
                {formBuilderScreenStrings.cancel}
              </button>
              <button
                className="slds-button slds-button_brand"
                onClick={handleSaveModal}>
                {formBuilderScreenStrings.checkIn}
              </button>
            </div>
          </section>
        </Modal>
      );
}