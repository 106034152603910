import axios from 'axios';
import { ConnectionInfo } from './IAccessManager';
import IAccessManager from './IAccessManager';
import StubAccessManager from './StubAccessManager';
import { getNameSpace,getFieldObjectApiNameWithNamespace } from './namespace';
import UserContext from './UserContext';

const USER_DETAILS = 'userDetails';
export default class SFAPI {
    accessManager: IAccessManager;

    constructor() {
        this.accessManager = new StubAccessManager();
    }

    public setAccessManager(accessManager : IAccessManager) : SFAPI {
        this.accessManager = accessManager;

        return this;
    }

    private async getUserContext() : Promise<UserContext> {
        return this.accessManager.getUserContext();
    }

    public isAuthorized () : boolean {
        let userContext = this.accessManager.getUserContextSync();
        
        return userContext.isAuthorized();
    }

    

    performRequest = async (method: string, path : string, data : any) => {
        const context = await this.getUserContext();
        console.log('Path :', path);
        if(!this.isAuthorized() && !path.startsWith('/api/content')) {
            return;
        }


        let response : any = null;
        let config =  {
            headers: {
                'x-sf-token': context.token,
                'x-sf-instance-url': context.url,
                'x-sf-instance-namespace': getNameSpace()
            }
        };
        
        try {
            if(method === 'get') {
                response = await axios.get(path, config);
            } else if (method === 'post') {
                response = await axios.post(path, data, config);
            } else if(method === 'put') {
                response = await axios.put(path, data, config);
            }
        } catch (ex) {
            if(ex.response.status === 401){
                if(localStorage.getItem(USER_DETAILS)){
                    window.location.reload();
                }
                localStorage.removeItem(USER_DETAILS);
            }
            this.accessManager.handleResponseErrors(ex, ex.response, path);
            return ex.response && ex.response.data;
        }

        return response && response.data;
    }

    public async setUserDetails (connection ) {
        let connectionInfo = new ConnectionInfo();
        connectionInfo.instanceUrl = connection.instanceUrl;
        connectionInfo.organizationId = connection.userInfo.organizationId;
        connectionInfo.token = connection.accessToken;
        await this.accessManager.setConnectionDetails(connectionInfo);

        await this.performGetRequest(`/api/userDetails?userId=${connection.userInfo.id}`)
        .then((data) => {
            this.accessManager.setUserDetails({
                details: data
            });
                //localStorage.setItem('userDetails',JSON.stringify(data));
            }).catch ((error) => {
                const errorObj = {
                    errorStatus: error.status,
                    errorMessage: error.error ? error.error : error.message,
                    componentName: ''
                };
                return errorObj
        });
        // updates the token maxAge with session timeout value that set in org
        await this.performGetRequest(`/api/updateTokenTime`)
        return Promise.resolve();
    };

    public async performGetRequest (path: string) {
        return this.performRequest('get', path, null);
    }

    public async performPostRequest (path: string, data: any) {
        return this.performRequest('post', path, data);
    }

    public async accessTokenStatus() {
        return await this.performPostRequest(`/api/accessTokenStatus`, undefined);
    }

    public async getForms() {
        return await this.performGetRequest('/api/forms');
    }

    public async getFormDefinition(recordId : string) {
        return await this.performGetRequest(`/api/schema?recordId=${recordId}`);
    }

    public async getAccessToken() {
        let response = await axios.get(`/api/getToken`);
        if(response.status !== 200) {
            throw new Error('Could not get token from server');
        }

        return response.data;
    }

    public async getFormsVersion() {
        let response = await axios.get('/api/version');
        if(response.status !== 200) {
            throw new Error('Could not get version');
        }

        return response.data;
    }

    public async searchFormItem(data : string) {
        return await this.performGetRequest(`/api/search?search=${data}`);
    }

    /**
     * @returns assigned tasks which are not started in inspection program
     */
    public async getAssignedTasks(){
        const userContext = await this.getUserContext();
        let currentUser : string = JSON.parse(String(userContext.userDetails)).Id;
        let checklistIdFieldName = getFieldObjectApiNameWithNamespace('SQX_Checklist__c');
        let checklistNameFieldName = getFieldObjectApiNameWithNamespace('SQX_Checklist__r');
        let assigneeFieldName = getFieldObjectApiNameWithNamespace('SQX_Assignee__c');
        let safetyInspectionObjectName = getFieldObjectApiNameWithNamespace('SQX_Safety_Inspection__c');
        let inspectionProgramObjectName = getFieldObjectApiNameWithNamespace('SQX_Inspection_Program__c');
        let query = `SELECT Id,Name,${checklistIdFieldName},${checklistNameFieldName+'.Name'}, (SELECT Id,Subject,WhatId,ActivityDate FROM Tasks WHERE Status = 'Not Started' AND IsRecurrence = false LIMIT 50) FROM ${inspectionProgramObjectName} {WHERECLAUSE} LIMIT 50`;
        let where = ` WHERE ${assigneeFieldName} = '${currentUser}' `;
        query = query.replace('{WHERECLAUSE}', where); 
        let url = `/api/searchQuery/?query=${encodeURIComponent(query)}`;
        let data = await this.performGetRequest(url);
        
        let assignedTaskRecords :any[] = [];
        type taskItem = {
            checklist: string;
            taskId: string;
            subject: string;
            scheduledFor: string;
        };
        let recordList = data.records;
        if(recordList && recordList.length){
            for(let i=0; i< recordList.length; i++){
                if(recordList[i].Tasks && recordList[i].Tasks.records.length){
                    let checklist = recordList[i][checklistIdFieldName] ? recordList[i][checklistNameFieldName].Name : '';
                    for(let j=0;j<recordList[i].Tasks.records.length;j++){
                        let assignedTaskItem :taskItem = {
                            'checklist' :  checklist,
                            'taskId' : recordList[i].Tasks.records[j].Id,
                            'subject' : recordList[i].Tasks.records[j].Subject,
                            'scheduledFor' : recordList[i].Tasks.records[j].ActivityDate
                        };
                        assignedTaskRecords.push(assignedTaskItem);
                    }
                }
            }
        }
        return assignedTaskRecords.sort((a, b) => new Date(b.scheduledFor).valueOf() - new Date(a.scheduledFor).valueOf());
    }

    public async findRecords(searchTerm: string, object: any, filter : any, order: any, fields: any) {
        let query = `SELECT Id, Name FROM ${object} {WHERECLAUSE} ORDER BY Name ASC, LastViewedDate DESC LIMIT 50`;
        let where = '';
        if(searchTerm && searchTerm.length > 0) {
            let escapedSearchTerm  = searchTerm.replace(/\'/g, '\\\'');
            where = `WHERE Name LIKE '${escapedSearchTerm}%25'`;
        }
        query = query.replace('{WHERECLAUSE}', where); 
        let url = `/api/searchQuery/?query=${encodeURIComponent(query)}`;
        console.log('querying: ' + url);
        let data : any = await this.performGetRequest(url);

        return (data && data.records) || [];
    }

    /**
     * @returns list of pending Submission records
     */
    public async findPendingSubmissions() {
        return await this.performGetRequest('/api/pendingSubmissions');
    }

    /**
     * @param recordId : submission id
     * @returns pendingformDefinition
     */
    public async syncPendingSubmissions(recordId) {
        return await this.performGetRequest(`/api/syncPendingSubmissions?recordId=${recordId}`);
    }

    /*
    * Method checks form status
    * @param recordIds form ids 
    * @returns form ids which are obsolete or forms removed from offline support
    */
    public async checkFormStatus(recordIds : string) {
        return await this.performGetRequest(`/api/checkFormStatus?recordIds=${recordIds}`);
    }
    
    /**
     * This method query record using recordId
     * @param recordId 
     * @param object 
     * @param fields 
     * @returns queried record
     */
    public async findRelatedRecord(recordId : string, object : string, fields : string){
        let filteredFields = await this.filterFields(object, fields);
        if(!filteredFields) return undefined;
        let query = `SELECT ${filteredFields} FROM ${object} WHERE Id = '${recordId}'`;
        let url = `/api/searchQuery/?query=${encodeURIComponent(query)}`;
        return await this.performGetRequest(url);
    }

    /**
     * This method performs graphQL post request
     * @param query 
     * @returns records
     */
    public async performGraphQLRequest(query : string){
        query = query.replace(/\s+/g, ' ').trim();
        return await this.performPostRequest('/api/recordgraphql', {'query':query});
    }

    /**
     * This method filter out the invalid object fields before performing query
     * @param object 
     * @param fields 
     * @returns 
     */
    public async filterFields(object : string, fields : any){
        let objectMetaData : any = await this.getSeletectedObjectMetadata(object);
        if(objectMetaData?.errorCode && objectMetaData?.errorCode === 'NOT_FOUND') return undefined;
        let objectFields = new Set();
        if(objectMetaData){
            if(!Array.isArray(fields)){
                fields = fields.split(',');
            }
            if(objectMetaData.fields){
                objectMetaData.fields.forEach(field => {
                    fields.map((item) => {
                        //skipping relation field
                        if(!item.includes('__r') && !item.includes('.')){
                            if(item.trim().includes(field.name)){
                                objectFields.add(item)
                            }
                        }
                    })
                });
            }
            //add escaped relation fields
            fields.map((field) => {
                if(field.includes('__r') && field.includes('.')){
                    objectFields.add(field);
                }
            })
            return Array.from(objectFields).join(',')
        }
        return fields;
    }


   /**
    * Method gets the selected object metadata for CQ Form Builder
    * @param objectApiName 
    * @returns metadata of selected object
    */
    public async getSeletectedObjectMetadata(objectApiName : String) {
        return await this.performGetRequest(`/api/selectedObjectFields?selectedObject=${objectApiName}`);
    }


   /**
    * Method gets SIC Recordtype documents having Status as 'Current' or 'Pre-release'
    * @returns SIC Control Documents list to show on right panel
    */
    public async getDocumentsOfSeletectedObjectMetadata(safetyCriteriaType : String) {
        let queryControlledDoc:any;
        let selectedObjectMetadata = await this.getSeletectedObjectMetadata('compliancequest__SQX_Controlled_Document__c');
        let isSafetyCriteriaTypeFieldAvailable = this.checkFieldAvailability(selectedObjectMetadata.fields, 'cqext__Safety_Criteria_Type__c');
        if(isSafetyCriteriaTypeFieldAvailable) {
            queryControlledDoc  = `Select Id, Name, compliancequest__Date_Of_Origin__c, compliancequest__Document_Number__c, compliancequest__Title__c, compliancequest__Document_Status__c, compliancequest__Revision__c,(Select Id,Name, compliancequest__Controlled_Document__c, compliancequest__Comment__c  From compliancequest__Related_Documents__r) From compliancequest__SQX_Controlled_Document__c  Where (compliancequest__Document_Status__c = 'Current' OR compliancequest__Document_Status__c= 'Pre-Release') AND RecordType.DeveloperName = 'Safety_Inspection_Criteria' AND cqext__Safety_Criteria_Type__c = '${safetyCriteriaType}' Order By CreatedDate Desc`;
        } else {
            queryControlledDoc  = `Select Id, Name, compliancequest__Date_Of_Origin__c, compliancequest__Document_Number__c, compliancequest__Title__c, compliancequest__Document_Status__c, compliancequest__Revision__c,(Select Id,Name, compliancequest__Controlled_Document__c, compliancequest__Comment__c  From compliancequest__Related_Documents__r) From compliancequest__SQX_Controlled_Document__c  Where (compliancequest__Document_Status__c = 'Current' OR compliancequest__Document_Status__c= 'Pre-Release') AND RecordType.DeveloperName = 'Safety_Inspection_Criteria' Order By CreatedDate Desc`;
        }
        let url = `/api/searchQuery/?query=${encodeURIComponent(queryControlledDoc)}`;
        let getAddedSectionSicDocList = await this.performGetRequest(url);        
        return getAddedSectionSicDocList ;
    }

    /**
     * This methods checks whether field is available on the object or not
     * @param mainObject 
     * @param searchingField 
     * @returns true or false
     */
    public checkFieldAvailability(mainObject:any, searchingField:any) {
        let stringifiedObject = JSON.stringify(mainObject);
        if(stringifiedObject.includes(searchingField)) {
            return true;
        }

        return false;
    }

    public async getStartingData(recordId : string, query : string, lookupCacheString : string) {
       
        return await this.performPostRequest(`/api/startingData?recordId=${recordId}`, {
            query, 
            lookupCacheString
        });
        
    }

    /**
     * Method used to call rest class that saves JSON built in Form Builder
     * @param recordId 
     * @param formJSON 
     * @returns 
     */
    public async saveFormData(recordId : string, formJSON : string, sectionIds : string, saveMethod?:string, comment?: string) {
        if(saveMethod === 'put') {
            return await this.performRequest(saveMethod,`/api/saveFormBuilderJSON?recordId=${recordId}`,{
                formJSON
            });
        }
        return await this.performPostRequest(`/api/saveFormBuilderJSON?recordId=${recordId}&&comment=${comment}`,{
            formJSON,
            sectionIds,
        });
    }

    /**
     * Method used to call rest class that get Form JSON that built using Form Builder
     * @param recordId 
     * @returns 
     */
    public async getSavedFormJSON(recordId : string) {
        return await this.performGetRequest(`/api/getSavedFormBuilderJSON?recordId=${recordId}`);
    }
    
}