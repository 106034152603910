import React, { useState, useContext } from 'react';
import strings from '../../localizations/homeScreen';
import { ReactComponent as NoResultLogo } from '../../assets/slds/images/empty-state-no-results.svg'
import { useHistory } from "react-router-dom";
import InProgressList from '../CQInProgressList';
import AssignedList from '../CQAssignedList';
import FormList from '../CQFormList';

import { uuidv4 } from '../../services/cqIdGenerator';
import CQSubmissionContext from '../../context/CQSubmissionContext';

import { Snackbar } from '@material-ui/core';

import CQCircularProgress from '../CQCircularProgress';
import CQSubmittedList from '../CQSubmittedList';
import { CQApiConstant } from '../../api/api-constants';
import Alert from '@salesforce/design-system-react/components/toast'; 
import { isMobileOnly } from 'react-device-detect';



function CQTable(props) {
    
    const submissionContext = useContext(CQSubmissionContext);

    let history = useHistory();
    const [selectingForm, setSelectingForm] = useState(false);
    const [errors, setErrors] = useState('');

    const createSubmission = async (form: any) => {
        //TODO: check if there is an open submission
        let id: string = uuidv4();
        setSelectingForm(true);
        try {
            let data = await submissionContext.getFormDefData(form.Id);
            if(data != null && typeof data === 'object'){
                let instanceUrl = localStorage.getItem('instanceurl') || '';
                await submissionContext.upsertSubmission(form.Id, data, form.Name, id,instanceUrl, new Date().toISOString());
                history.push(`/form/${id}`);
            }
            else if (typeof data === 'string'){
                throw {response: {data : data}}
            }
        } catch (e) {
            setSelectingForm(false);
            if(e.response && e.response.data){
                setErrors(strings.serverError + JSON.stringify(e.response.data));
            }else{
                if(e.message === 'Network Error'){
                    setErrors(strings.networkError);
                }else{
                    setErrors(JSON.stringify(e.getMessage()));
                }
            }
        }
    }

    const startSubmission = async (form: any) => {
        
        let id: string = uuidv4();
        setSelectingForm(true);
        try {
            await submissionContext.startSubmission(form, id );
            history.push(`/form/${id}`);
        } catch (e) {
            setSelectingForm(false);
            if(e.response && e.response.data){
                setErrors(strings.serverError + JSON.stringify(e.response.data));
            }else{
                if(e.message === 'Network Error'){
                    setErrors(strings.networkError);
                }else{
                    setErrors(JSON.stringify(e.getMessage()));
                }
            }
        }
    }

    return (<>
        <CQCircularProgress isLoading={selectingForm}/>
        <div className={isMobileOnly ? "cq-table-container" : "slds-card"}>
                {   
                    (props.data === undefined || props.data.length === 0)
                    ? <div
                        style={{
                            display : 'flex',
                            flexDirection : 'column',
                            alignItems : 'center',
                            overflow: 'hidden',
                        }}>
                        <NoResultLogo/>
                        <h1>{strings.NoData}</h1>
                      </div>
                    : 
                    <React.Fragment>
                        {props.submittedTab === true ?
                           <Alert
                           variant="info"
                           labels={{heading: strings.formSubmissionMessage}}
                           duration={0}
                           icon=''
                           className= 'cq-submittedtab-alert'
                            />:<></>
                        }
                    
                        {props.data.map((item, index) => {

                            return(
                                <React.Fragment key={index}>
                                    {props.submissionType === 'assigned'?
                                    <AssignedList item={item}/>
                                    : 'formName' in item && item.status === CQApiConstant.SUBMISSION.STATUS_SUBMITTED && (item.syncStatus === CQApiConstant.SUBMISSION.SYNC_STATUS_OUT_OF_SYNC ||  item.syncStatus === CQApiConstant.SUBMISSION.SYNC_STATUS_FILE_UNSYNC)?
                                        <CQSubmittedList item={item}/>
                                    :'formName' in item ?
                                        <InProgressList item={item}/>
                                            : index < 20 ?
                                                <FormList createSubmission={createSubmission} item={item}/>
                                    :null
                                    }
                                </React.Fragment>  
                            )
                        })}
                    </React.Fragment>
                }       
            </div>
        {errors !== '' ?
        <Snackbar
            autoHideDuration={6000}
            open
            onClose={() => setErrors('')}
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
            message={errors}
        /> : ''
        }

    </>);
}

export default CQTable;
