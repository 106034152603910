import React from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { isSalesforceBuild } from '../salesforceBuild';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { adjustTextareaHeights } from 'services/adjustTextareaHeight';


const CQStaticTextControl = React.memo(({
  data,
  handleChange,
  path,
  visible,
  enabled,
  uischema,
  errors,
  schema
}: any) => {

  const handleQuillChange = (value: string) => {
    if (value !== data) { // Only update if the value has changed
      handleChange(path, value);
    }

    // Adjust height for mobile or tablet devices
    if (isMobileOnly || isTablet) {
      const textarea = document.querySelector('.ql-editor') as HTMLElement;
      if (textarea) {
        adjustTextareaHeights({ target: textarea });
      }
    }
  };

  if (!visible) {
    return null;
  }
  
  const getRichText = () => {
    if(uischema.options?.title !== '') {
      return uischema.options.title;
    }else {
      return "";
    }
  }

  return (
    <div className={uischema.options?.headerBackground ? "cq-quill" : "cq-quill-white"}>
      <ReactQuill
        value={getRichText()}
        onChange={handleQuillChange}
        readOnly={true}
        modules={{}} // No toolbar
      />
      {errors && <div style={{ color: 'red', fontSize: '0.875rem' }}>{errors}</div>}
    </div>
  );
});

export default withJsonFormsControlProps(CQStaticTextControl);