import LocalizedStrings from 'react-localization';


let strings = new LocalizedStrings({
  en: {
    cqFormBuilder: 'CQ Form Builder',
    saveForm : 'Save Form',
    checkIn: 'Check In',
    checkInTitle : 'Check In Controlled Document',
    editLogo : 'Edit Logo',
    addLogo : 'Add Logo',
    addSignature: 'Add Signature',
    fieldSearch: 'Search for a field or document',
    infoLabel: 'info',
    deleteLabel: 'delete',
    readOnlyLabel: 'Read Only',
    headerField: 'Header Field',
    dragLabel: 'drag',
    requiredLabel: 'Required',
    hideLabel: 'Hide Label',
    generalSection: 'Input Fields',
    documentsSection: 'Documents',
    fieldsSection: 'Primary Fields',
    relatedFieldsSection: 'Secondary Fields',
    signature: 'Signature',
    save: 'Save',
    cancel: 'Cancel',
    signaturePlaceholder: 'Enter text',
    logoTypeError: 'Logo should be jpg or png type',
    fieldLabel: 'Field Label',
    apiName: 'API Name',
    default: 'Default Value',
    generalFieldPlaceholder: 'TextArea Text',
    generalRichTextPlaceholder: 'Please enter text here...',
    dateFieldPlaceholder: "Please enter dates in MM/DD/YYYY HH:MM AM/PM format",
    dropdownDefaulPlaceholder: 'Default value has to be one of the available picklist values',
    picklistValues: 'Picklist Values',
    add: 'Add',
    picklistPlaceholder: 'Enter picklist value',
    successMessage: 'Changes Saved Successfully.',
    documentErrorMessage: 'Document Criteria is not setup correctly.',
    addFileAttachment: 'Add File Attachment',
    addGrid: "Add Grid",
    addRow: "Add Row",
    removeRow: "Remove Row",
    addColumn: "Add Column",
    removeColumn: "Remove Column",
    updateHeader: "Update Header",
    tableHeader: 'CQ Form Dynamic Table Header',
    headerTitle: 'Label',
    columnModalHeader: 'Update Column Label',
    relnAPIName: 'Relational API Name',
    columnTitle: 'Column Label',
    selectObject: 'Select Objects',
    mappedFieldObject: 'Primary Object',
    contextFieldObject: 'Secondary Object',
    searchObjectPlaceholder: 'Search Salesforce',
    addSection: 'Add Section',
    selectObjectNext: 'Next',
    contextHelpText:'This is the object related to your primary object. These are your informational fields only. The purpose of these fields is to help the give more related information to your form. These fields will be read only.',
    mainObjectHelpText: 'This is the object your form will be related to and where you will launch it from. The fields you will drag and drop on your form will be editable and will update your related record when saved.',
    dropTable: 'Drop Tables/Grids Here',
    apiNameDuplicateError: 'API Name has been used before. API Names must be unique on the same form',
    relnAPINameError : 'Relation API Name has been used berfor. Relation API Name must be unique on the same form',
    relnAndAPIError : 'Relation API Name and API Name cannot be same',
    inputValueError: 'Column value should not be emplty.',
    validationRule: 'Validation Rule',
    validationRuleCode: 'Validation Rule Code',
    validationErrorMessage: 'Error Message Text',
    saved: 'Saved',
    saveFailed: 'Save Failed',
    layouts: 'Layouts',
    relationalGrid: 'Relational Grid',
    comment: 'Comment',
    staticField: 'Static Field'
  }
});

export default strings;