import React from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Textarea from '@salesforce/design-system-react/components/textarea';
import { isSalesforceBuild } from '../salesforceBuild';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { adjustTextareaHeights } from 'services/adjustTextareaHeight';

const CQTextAreaControl = ({data, handleChange, path, required, visible, label, enabled, uischema, errors, schema}: any) => {

  const handleTextareaChange = (evt : any) => {
    data = evt.target.value
    handleChange(path, data);
    //checking device is mobile or tablet.  
    //isTablet property returns true not only for tablets but also for larger mobile devices. To exclude larger mobile devices !isMobile is checking
    if(isMobileOnly || isTablet){
      adjustTextareaHeights(evt);
    }
  }

  return (
    <>{visible?
      <Textarea
        className={(isMobileOnly || isTablet) ? "cq-textarea cq-textarea-mobile" : "cq-textarea"}
				label={uischema.options?.hideLabel ? '' : label}
        errorText={errors}
        required={required}
        onChange={handleTextareaChange}
        value={data}
        disabled={isSalesforceBuild() || !enabled}
      />:''}
		</>
  );
};

export default withJsonFormsControlProps(CQTextAreaControl);