import { CQFormBuilderConstants } from "./formbuilder-constants";
import { CQFSchemaBuilder } from './schemaBuilder';


/**
 * This method is used to fetch the sObject from the org
 * @returns list of sObject from org
 */
export const fetchSObjects = async (): Promise<Array<object>> => {
    let schemaBuilder = new CQFSchemaBuilder();
    let listOfSObjects: Array<object> = [];

    try {
        const result = await schemaBuilder.objectApiCall();
        if (result) {
            const sObjectData: any = result['sobjects'];
            if (sObjectData) {
                sObjectData.filter((elem, index) => {
                    if (
                        elem['custom'] &&
                        elem.name.match(CQFormBuilderConstants.NAMESPACE_REGEX) &&
                        !elem.name.endsWith('__mdt') &&
                        !elem.name.endsWith('__e') &&
                        !elem['customSetting'] &&
                        elem.name.toLowerCase() !== 'compliancequest__sqx_audit__c'
                    ) {
                        let obj: any = {
                            label: elem.label,
                            id: index,
                            type: elem.name,
                        };
                        listOfSObjects.push(obj);
                    }
                });
            }
        }
    } catch (error) {
        console.error('Error fetching custom objects:', error);
    }

    return listOfSObjects;
};