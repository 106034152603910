
import moment from 'moment';
import {mapDataToSubmission, addSignatureInSubmissionData} from '../services/data-mapping.service'
import SFAPI from './sfapi';
import { getNameSpaceField } from './namespace';
import IAccessManager from './IAccessManager';
import StubAccessManager from './StubAccessManager';
import { CQSubmission } from './CQSubmissionManager';
import { isIndexAvailable, storeSubmissionInDB, updateSubmissionDBItem } from './submissiondbapi';
import { CQGraphQLProcessor } from './CQGraphQLProcessor';
  interface FieldValue {
    value: string | number | null | undefined;
  }
  
  interface Node {
    [key: string]: FieldValue | { edges: { node: Node }[] };
  }
  
  interface Edge {
    node: Node;
  }
  
  interface QueryResult {
    [key: string]: {
      edges: Edge[];
    };
  }
  
  interface UIApiData {
    query: QueryResult;
  }
  
  interface InputData {
    data: {
      uiapi: UIApiData;
    };
    errors: any[];
  }
  
  interface AdditionalNodesMap {
    [key: string]: string;
  }


export class CQAutoFillup{

    public static GENERAL = 'General';
    public static REFERENCE_FIELD_GROUP = 'Group';
    public static REFERENCE_FIELD_USER = 'User';

    public static SQX_Safety_Inspection = "SQX_Safety_Inspection__c";

    sfAPI : SFAPI;
    accessManager: IAccessManager = new StubAccessManager();

    constructor(accessManager ?: IAccessManager){
        this.sfAPI = new SFAPI();
        if(accessManager !== undefined){
            this.setAccessManager(accessManager)
        }
    }

    /**
     * method to retrieve content form controlled document and update the data if main record id exists
     */
     public async updateContentAccordingFormSubmissionRecordId(formSubmissionRecord){
        try{
            let submission = await this.sfAPI.syncPendingSubmissions(formSubmissionRecord.Id);
            if(submission && submission.hasOwnProperty('status')){
                let isSubmissionAvailable = await isIndexAvailable(submission.id)
                //sync salesforce data ( Note : need to chcek for default data)
                let syncedDataSubmission = await this.processData(submission.formDef, formSubmissionRecord);
                submission.data = syncedDataSubmission.data;
                submission = CQSubmission.syncSubmission(submission);
                if(isSubmissionAvailable){
                    await updateSubmissionDBItem(submission);
                }else{
                    await storeSubmissionInDB(submission);
                }
                return submission;
            }else if(submission && submission.hasOwnProperty('schema','ui')){
                return await this.processData(submission, formSubmissionRecord);
            }else{
                //handling this part for migration issue. Since in v1, we don't have any form JSON under related form submission record
                let formDef  =  await this.sfAPI.getFormDefinition(getNameSpaceField(formSubmissionRecord, 'SQX_Controlled_Document__c'));
                if(formDef){
                    return await this.processData(formDef, formSubmissionRecord);
                }
                return submission;
            }
        }catch(error){
            console.error(error);
        }
    }

    /**
     * @description process the submission data 
     * @param submission 
     * @param formSubmissionRecord 
     * @returns processed submission
     */
    private async processData(submission : any, formSubmissionRecord: any){
        let formDef = Object.assign({}, submission);
        let startingData : any = {};
        let keys : any = submission.schema.properties
        let map : Map<string, string> = new Map<string, string>();
        let data : any = undefined;
        let objectKey : any = Object.keys(keys)
        if (objectKey.length > 1 || !formDef.hasOwnProperty('cqFormType', 'cqFormObjectSchema')){
            if(objectKey.indexOf(CQAutoFillup.GENERAL) > -1){ 
                let generalIndex : number = objectKey.indexOf(CQAutoFillup.GENERAL); 
                objectKey.splice(generalIndex, 1); 
            }
            let fieldKeys : any = Object.keys(keys[objectKey[0]].properties);
            let relationFields : any = fieldKeys.filter(element => element.endsWith('__r'));
            fieldKeys = fieldKeys.filter(element => !element.endsWith('__r'));
            let relationshipSchema = await mapDataToSubmission({ data : {}, formDef});
            if(fieldKeys.length > 0){
                // check if context object is selected and query it using relational field
                //TODO: will resolve autofill for nested grandchild records using GRAPHQL ( placing previous code for inspection case)
                if(this.checkSafetyInspection(submission)){
                   // check if context object is selected and query it using relational field
                      if(submission.hasOwnProperty('contextObject') && submission.hasOwnProperty('contextObjectRelationshipName')) {
                        if(submission.contextObject !== "") {
                            let contextFieldsToQuery : any = this.getContextFieldsToQuery(submission);
                            // check if there is any context fields to query and add it on field keys
                            if(contextFieldsToQuery.length > 0 ) {
                                fieldKeys.push(...contextFieldsToQuery);
                            }
                        }
                    }
                    data = await this.sfAPI.findRelatedRecord(getNameSpaceField(formSubmissionRecord, 'Main_Record_Id__c'), objectKey[0], fieldKeys);
                    if(data && data.records[0]) {
                      startingData[objectKey[0]] = data.records[0];
                    }
                }
                else if(relationFields && !this.checkSafetyInspection(submission)){
                    let relationFieldsToQuery : any = await this.getRelationFieldsToQuery(submission, relationFields, objectKey[0], fieldKeys, getNameSpaceField(formSubmissionRecord, 'Main_Record_Id__c'));
                    const query = relationFieldsToQuery.query;
                    map = relationFieldsToQuery.map;
                    data = await this.sfAPI.performGraphQLRequest(query);
                    data['records'] = [this.transformData(data.data, Object.fromEntries(map))]
                    if(data && data.records[0]) {
                        //check if any table information is missing from graphQL data if yes, then provide missing keys and merge those default data in submission data
                        let missingKeys : Array<string> = Object.keys(submission.data[objectKey[0]]).filter((key) => JSON.stringify(data.records[0]).includes(key))
                        data.records[0] = this.mergeObjects(submission.data, data.records[0], missingKeys);
                        startingData[objectKey[0]] = data.records[0][objectKey[0]];
                    }
                }
                
                if(submission.queries.length !== 0 && Object.keys(relationshipSchema['data']).length !== 0){
                    // added condition to check if multiple relationfields are available
                    if(Array.isArray(relationFields)) {
                        relationFields.map(relationField => {
                            // check if data has value for relationfields or not
                            let contextObjectRelationshipName : any = submission.contextObjectRelationshipName;
                            relationshipSchema['data'][objectKey[0]][contextObjectRelationshipName] = {};
                            // check whether the relationField is contextObjectRelationshipname or not
                            if(relationField === contextObjectRelationshipName){
                                relationshipSchema['data'][objectKey[0]][contextObjectRelationshipName] = startingData[objectKey[0]][relationField];
                            }
                            if(contextObjectRelationshipName !== relationField && relationshipSchema['data'][objectKey[0]][relationField]) {
                                startingData[objectKey[0]] = startingData[objectKey[0]] || {};
                                startingData[objectKey[0]][relationField] = relationshipSchema['data'][objectKey[0]][relationField];
                            }else if (relationshipSchema['data'][objectKey[0]][relationField] === null || relationshipSchema['data'][objectKey[0]][relationField] === undefined){
                                delete startingData[objectKey[0]][relationField];
                            }
                        })
                    } else {
                        startingData[objectKey[0]][relationFields] = relationshipSchema['data'][objectKey[0]][relationFields];
                    }
                }
                //signature will be added in data if present in formDef
                startingData = addSignatureInSubmissionData(submission, startingData);
                if(Object.keys(startingData).length > 0){
                    submission.data = await this.formatData(startingData, fieldKeys, objectKey[0], submission);
                }
            }
        }
        return submission;
    }

    /**
     * Merges two objects
     * @param submissionData 
     * @param graphQLData 
     * @param addParam : additional param which is missing from the graphQL data 
     * @returns new object merging both objects ( missing default data added )
     */
    private mergeObjects = ( submissionData: Record<string, any>,graphQLData: Record<string, any>,addParam?: string[]) => {
        // Helper function to merge two objects deeply
        function mergeDeep(target: Record<string, any>, source: Record<string, any>) {
            for (const key in source) {
                if (source.hasOwnProperty(key)) {
                    if (source[key] instanceof Object && key in target && target[key] instanceof Object) {
                        // If both values are objects, recursively merge them
                        mergeDeep(target[key], source[key]);
                    } else {
                        // Otherwise, assign the value from source to target
                        target[key] = source[key];
                    }
                }
            }
            return target;
        }
    
        // If addParam is provided, check for missing keys in graphQLData and merge them from submissionData
        if (addParam && addParam.length > 0) {
            const modifiedGraphQLData = JSON.parse(JSON.stringify(graphQLData)); // Deep clone
    
            for (const key of addParam) {
                if (!(key in modifiedGraphQLData) && key in submissionData) {
                    // Only merge if the key is missing in graphQLData but exists in submissionData
                    modifiedGraphQLData[key] = JSON.parse(JSON.stringify(submissionData[key]));
                }
            }
    
            // Now merge the modified graphQLData into submissionData
            const merged = JSON.parse(JSON.stringify(submissionData));
            return mergeDeep(merged, modifiedGraphQLData);
        }
    
        // Default behavior (no addParam provided)
        const merged = JSON.parse(JSON.stringify(submissionData));
        return mergeDeep(merged, graphQLData);
    };

    /**
     * This method returns list of context fields to query
     * @param submission 
     * @returns list of context fields
     */
        public getContextFieldsToQuery(submission:any) {
          let contextFields : any = [];
          let contextObject : any = submission.contextObject;
          let contextObjectRelationshipName : any = submission.contextObjectRelationshipName;
          Object.keys(submission.schema.definitions[contextObject]['properties']).map(field => {
              contextFields.push(`${contextObjectRelationshipName}.${field}`);
          })
          return contextFields;
      }

    /**
     * This method returns list of context fields to query
     * @param submission
     * @param relationFields
     * @param primaryObjectKey
     * @param mainObjectFields
     * @param mainRecordId 
     * @returns object consisting relationFieldMap and graphlQL query
     */
    public async getRelationFieldsToQuery(submission: any,relationFields: string[],primaryObjectKey: string,mainObjectFields: string[], mainRecordId : string): Promise<{ map: Map<string, string>, query: string }>{
        let childFields: { field: string; fields: string[] }[] = [];
        let relationalFieldToAPIMapping = new Map<string, string>(); //stores the actual api name and its relational api name
        await Promise.all(
          relationFields.map(async (object) => {
            const relationObjectInfo = submission.schema?.properties[primaryObjectKey]?.properties[object];
            const relationalObjectAPIName = relationObjectInfo?.items.hasOwnProperty('$ref') ? relationObjectInfo?.items?.$ref?.split('/').pop() : Object.keys(relationObjectInfo?.items?.properties)[0]; // schema check
            if (!relationalObjectAPIName) return;
            const relationFieldAPIs = Object.keys(
              submission.schema.definitions[relationalObjectAPIName]?.properties?.[relationalObjectAPIName]?.properties || {}
            );
            relationalFieldToAPIMapping.set(object, relationalObjectAPIName);
            let validFields = await this.sfAPI.filterFields(relationalObjectAPIName, relationFieldAPIs);
            if(!validFields.length) return;
            childFields.push({ field: object, fields: validFields.split(',')});
          })
        );
        const query =  new CQGraphQLProcessor(primaryObjectKey, mainRecordId, mainObjectFields, childFields).buildQuery() 
        return { map: relationalFieldToAPIMapping , query: query};
    };

    /**
     * This method formats data from a GraphQL response into a more structured JSON format.
     * @param inputData The GraphQL response data.
     * @param additionalNodesMap A map of additional nodes to include in the transformation.
     * @returns Transformed JSON data.
     */
    public transformData(inputData: InputData, additionalNodesMap: AdditionalNodesMap) {
      // Extract the main object key from the GraphQL response
      const objectKey = this.getObjectKey(inputData);
      // Extract the edges (records) for the main object
      const objectEdges = this.getObjectEdges(inputData, objectKey);
      // Transform the data
      const transformedData = this.transformObjectEdges(objectEdges, objectKey, additionalNodesMap);
      return transformedData;
    }

    /**
    * Extracts the main object key from the GraphQL response.
    * @param inputData The GraphQL response data.
    * @returns The main object key (e.g., "Account").
    */
    private getObjectKey(inputData: InputData): string {
      return Object.keys(inputData?.data?.uiapi?.query || {})[0];
    }

    /**
    * Extracts the edges (records) for the main object.
    * @param inputData The GraphQL response data.
    * @param objectKey The main object key.
    * @returns An array of edges (records).
    */
    private getObjectEdges(inputData: InputData, objectKey: string): Edge[] {
      return inputData?.data?.uiapi?.query?.[objectKey]?.edges || [];
    }

    /**
    * Transforms the edges (records) into a structured JSON format.
    * @param objectEdges The edges (records) to transform.
    * @param objectKey The main object key.
    * @param additionalNodesMap A map of additional nodes to include in the transformation.
    * @returns Transformed JSON data.
    */
    private transformObjectEdges(objectEdges: Edge[], objectKey: string, additionalNodesMap: AdditionalNodesMap): { [key: string]: any } {
      const transformedData: { [key: string]: any } = {};
      objectEdges.forEach((object: Edge) => {
          if (!object.node) return;

          // Transform the main object node
          const newObject = this.transformNode(object.node);

          // Transform additional nodes (if any)
          this.transformAdditionalNodes(newObject, object.node, additionalNodesMap);

          // Add the transformed object to the result
          transformedData[objectKey] = newObject;
      });

      return transformedData;
    }

    /**
    * Transforms a single node into a simplified JSON object.
    * @param node The node to transform.
    * @returns A simplified JSON object.
    */
    private transformNode(node: Node): { [key: string]: any } {
      const newNode: { [key: string]: any } = {};

      Object.entries(node).forEach(([key, value]) => {
          if ((value as FieldValue)?.value !== null && (value as FieldValue)?.value !== undefined) {
              newNode[key] = (value as FieldValue).value;
          }
      });
      return newNode;
    }

    /**
     * Check if a JOSN is a safety inspection JSON
     * Note: will remove, when handling graphQL for nested grandchild objects and its field
     */
    private checkSafetyInspection(submission : any){
      let formDef = submission.hasOwnProperty('formDef') ? submission.formDef : submission;
      return formDef?.cqFormType.includes(CQAutoFillup.SQX_Safety_Inspection);
    }

    /**
    * Transforms additional nodes and adds them to the main object.
    * @param newObject The main object being transformed.
    * @param node The current node being processed.
    * @param additionalNodesMap A map of additional nodes to include in the transformation.
    */
    private transformAdditionalNodes(newObject: { [key: string]: any },node: Node,additionalNodesMap: AdditionalNodesMap) {
      Object.entries(additionalNodesMap).forEach(([collectionKey, nodeType]) => {
          const edges = (node[collectionKey] as { edges: { node: Node }[] })?.edges;

          if (edges?.length) {
              const transformedNodes = edges
                  .map((edge: { node: Node }) => this.transformNode(edge.node))
                  .map((newNode: { [key: string]: any }) => (Object.keys(newNode).length > 0 ? { [nodeType]: newNode } : null))
                  .filter((node: any) => node !== null);

              if (transformedNodes.length > 0) {
                  newObject[collectionKey] = transformedNodes;
              }
          }
      });
    }

    /**
     * This method format the time and lookup values according to JSON form
     * @param startingData : form starting values
     * @param fieldKeys : fields api name
     * @param objectKey : object api name
     * @param formDef : JSON data
     * @returns starting data
     */
    public async formatData(startingData, fieldKeys, objectKey, formDef){
        for(let i = 0 ; i < fieldKeys.length ; i++){
            if(startingData[objectKey][fieldKeys[i]] === undefined || startingData[objectKey][fieldKeys[i]] === null){
                delete startingData[objectKey][fieldKeys[i]];
                continue;
            }
            let value = startingData[objectKey][fieldKeys[i]].toString();
            const datePattern : RegExp = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)[+]([0-9])*/gm
            if(datePattern.test(value)){
                startingData[objectKey][fieldKeys[i]] = moment.utc(moment(startingData[objectKey][fieldKeys[i]])).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            }
            let referenceField = formDef.schema.properties[objectKey].properties[fieldKeys[i]]?.lookupTo;        
            if(referenceField !== null && referenceField !== undefined){
                startingData[objectKey][fieldKeys[i]] = await this.processLookUpData(startingData, objectKey, fieldKeys[i], formDef, referenceField, false);
            }
        }

        if(startingData[objectKey].hasOwnProperty(formDef.contextObjectRelationshipName)){
            for(const item in startingData[objectKey][formDef.contextObjectRelationshipName]){
                if(startingData[objectKey][formDef.contextObjectRelationshipName][item] === undefined || startingData[objectKey][formDef.contextObjectRelationshipName][item] === null){
                    delete startingData[objectKey][formDef.contextObjectRelationshipName][item];
                    continue;
                }
                if(JSON.stringify(fieldKeys).includes(item)){
                    let referenceField = formDef.schema.definitions[formDef.contextObject]['properties'][item]?.lookupTo;
                    if(referenceField !== null && referenceField !== undefined) {
                         startingData[objectKey][formDef.contextObjectRelationshipName][item] = await this.processLookUpData(startingData, objectKey, item, formDef, referenceField, true);
                    }
                }
            }
        }

        return startingData;
    }

    /**
     * This method process lookup schema
     * @param startingData 
     * @param objectKey 
     * @param fieldKey 
     * @param referenceField 
     * @returns 
     */
    public processLookUpData = async (startingData, objectKey, fieldKey, formDef, referenceField, isContextObject) => {
        let data = await this.sfAPI.findRelatedRecord( isContextObject ? startingData[objectKey][formDef.contextObjectRelationshipName][fieldKey] : startingData[objectKey][fieldKey], referenceField, 'Name');         
        if(referenceField === CQAutoFillup.REFERENCE_FIELD_GROUP && !data.records.length){
            data = await this.sfAPI.findRelatedRecord(isContextObject ? startingData[objectKey][formDef.contextObjectRelationshipName][fieldKey] : startingData[objectKey][fieldKey], CQAutoFillup.REFERENCE_FIELD_USER, 'Name');
            return data.records.length ? data.records[0].Name : '';
        }else{
            return data.records.length ? data.records[0].Name : '';
        }
    }

    public setAccessManager(accessManager : IAccessManager) : CQAutoFillup {
        this.accessManager = accessManager;
        this.sfAPI.setAccessManager(accessManager);

        return this;
    }

}