import React,{ useContext, useEffect } from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Input from '@salesforce/design-system-react/components/input';
import { isSalesforceBuild } from '../salesforceBuild';
import CQSubmissionContext  from '../context/CQSubmissionContext'; 
import { setEditedValueinOption, getEditedValueFromOption } from 'services/data-changeindicator.service';


const CQDateControl = ({data, handleChange, path, required,visible, label, enabled, schema, uischema, errors}: any) => {

  useEffect(() => {
    if(!data  && schema.hasOwnProperty('default')){
      if(schema?.default.length){
        handleChange(path, schema.default);
      }
    }
  },[schema])

  const submissionContext = useContext(CQSubmissionContext);
  const handleDateChange = (evt : any) => {
    data = evt.target.value
    handleChange(path, data);
    uischema = setEditedValueinOption(data, submissionContext.submission, path, uischema); 
  }

  return (
    <>
    {visible?
    <Input
      className={ !isSalesforceBuild() && getEditedValueFromOption(uischema,data,path,submissionContext.submission) ? 'cq-input-edit' : ''} 
      type="date"   
      label={uischema.options?.hideLabel ? '' : label}
      required={required}
      errorText={errors}  
      onInput={handleDateChange}
		  value={data}
      disabled={isSalesforceBuild() || !enabled}
    />:''}
    </>
  );
};

export default withJsonFormsControlProps(CQDateControl);