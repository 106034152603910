import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import formBuilderScreenStrings from "localizations/formBuilderScreen";
import { adjustTextareaHeights } from "services/adjustTextareaHeight";
import IconSettings from "@salesforce/design-system-react/components/icon-settings";
import Icon from "@salesforce/design-system-react/components/icon";
import CQSubmissionContext from "context/CQSubmissionContext";
import CQFormBuilderTableModal from "components/editor/CQFormBuilderTableModal";
import { Droppable } from "react-beautiful-dnd";
import {ResolvedJsonFormsDispatch, withJsonFormsControlProps,} from "@jsonforms/react";
import { CQAppConstant } from "app-constants";
import SFAPI from "api/sfapi";
import IAccessManager from "api/IAccessManager";
import AccessManagerContext from "context/AccessManagerContext";
import strings from "localizations/formScreen";
import { composePaths } from "@jsonforms/core";

const CQDynamicLayout = (props) => {
  const {
    uischema,
    schema,
    path,
    cells,
    visible,
    renderers,
    enabled,
    rootSchema,
    config,
    data,
    handleChange
  } = props;
  const submissionContext = useContext(CQSubmissionContext);
  const submissionInfo: any = submissionContext?.submission;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [gridOptions, setGridOptions] = useState([]);
  const [tableTitleValue, setTableTitleValue] = useState("");
  const hasNoElements = uischema.elements.length === 0;
  const selectedObjectName = path.split(".")[0];
  const layoutId = useRef<string>("");
  const layoutRelationalId = useRef(path.split(".").pop());
  const selectedRelationalGrid = useRef(
    uischema.options?.relationalGrid ? uischema.options?.relationalGrid : ""
  );
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  const accessManager: IAccessManager = useContext(AccessManagerContext);
  const sfAPI = new SFAPI().setAccessManager(accessManager);
  const [isLoaded, setIsLoaded] = useState(false);
  const [validSchema, setValidSchema] = useState<any>(() => {
    if (schema?.type === "array") {
      layoutId.current = Object.keys(schema.items.properties)[0];
      return schema.items.properties[layoutId.current];
    }
    return schema;
  });
  const [tableHeader, setTableHeader] = useState({ title: validSchema.title });
  const [columnValue, setColumnValue] = useState<any>({});
  const [isDomLoaded, setIsDomLoaded] = useState(false);
  const textareas: any = document.getElementsByClassName(
    `textarea-layout-${path.split(".").pop()}`
  );
  useEffect(() => {
    if (document.readyState === "complete") {
      setIsDomLoaded(true);
    } else {
      const onLoad = () => setIsDomLoaded(true);
      window.addEventListener("load", onLoad);
      return () => window.removeEventListener("load", onLoad);
    }
  }, []);

  useEffect(() => {
    const updateTextareaHeights = () => {
      let maxScrollHeight = 0;

      for (let i = 0; i < textareas.length; i++) {
        textareas[i].style.height = "auto"; // Reset height to auto to get the correct scrollHeight
        if (textareas[i].scrollHeight > maxScrollHeight) {
          maxScrollHeight = textareas[i].scrollHeight;
        }
      }

      for (let i = 0; i < textareas.length; i++) {
        textareas[i].style.height = `${maxScrollHeight}px`;
      }
    };

    if (isDomLoaded) {
      updateTextareaHeights();
    }
  }, [isDomLoaded, columnValue]);

  useEffect(() => {
    setIsLoaded(true);
  }, [uischema.options.rowsCount]);

  /**
   * This method gets rows from uischema count
   * @returns
   */
  const getRows = () => {
    if (uischema.options.hasOwnProperty("rowsCount")) {
      let tableRows: any = [];
      for (let i = 0; i < uischema.options.rowsCount; i++) {
        tableRows.push([]);
      }
      for (let i = 0; i < getColumns().length; i++) {
        tableRows.map((row: any) =>
          row.push({
            value: undefined,
            for: getColumns()[i].name,
            isEdited: false,
          })
        );
      }
      return tableRows;
    }
  };

  /**
   * This method gets columns from the schema and sort them accoring to their keys
   * @returns
   */
  const getColumns = () => {
    let tableColumns: any = [];
    const columnName = Object.keys(uischema.options.columns).sort((a, b) => {
      if (uischema.options.columns[a].id < uischema.options.columns[b].id)
        return -1;
      if (uischema.options.columns[a].id > uischema.options.columns[b].id)
        return 1;
      return 0;
    });
    columnName.forEach((key) => {
      const title = uischema.options.columns[key].title;
      const type = uischema.options.columns[key].type;
      const regexRuleName = uischema.options.columns[key].regexRuleName;
      const regexCode = uischema.options.columns[key].pattern;
      const message = uischema.options.columns[key].errorMessage;
      const width = uischema.options.columns[key].width;
      tableColumns.push({
        title: title,
        name: key,
        regexRuleName: regexRuleName,
        pattern: regexCode,
        errorMessage: message,
        type,
        width
      });
    });
    return tableColumns;
  };

  const [columnWidths, setColumnWidths] = useState({});
  const currentColumnWidth = useRef({});
  const initialWidth = 350;

  useEffect(() => {
    if (uischema.options.hasOwnProperty("columns")) {
      getColumns().map((column) => {
        if (column.width !== null && column.width !== undefined) {
          Object.assign(columnWidths, { [column.name]: column.width });
        }
      });
      setColumnWidths(columnWidths);
    }
  }, [uischema]);
  /**
   * @description This method is used to set the increase and descrease width for the column
   * @param event
   * @param index
   */
  const handleMouseDrag = (event, index) => {
    let startPoint = event.pageX;
    //This method is used to calcutate the change in the mouse position and update the column width accordingly
    const handleMouseMove = (moveEvent) => {
      const deltaX = moveEvent.pageX - startPoint;
      setColumnWidths((prevColumnWidths) => {
        const newWidths = { ...prevColumnWidths };
        newWidths[getColumns()[index].name] = Math.max(
          50,
          initialWidth + deltaX
        );
        currentColumnWidth.current = newWidths;
        return newWidths;
      });
    };
    //this method is used to removes the event listeners for stoping the draging operation
    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      if (Object.keys(currentColumnWidth.current).length) {
        const updateColumnWidthDynamicLayout = new CustomEvent(
          CQAppConstant.EVENT_LISTENER.CQ_UPDATE_COLUMN_WIDTH_DYNAMIC_LAYOUT,
          {
            bubbles: true,
            detail: {
              columnWidths: currentColumnWidth.current,
              relationalId: layoutRelationalId.current,
              columnId: getColumns()[index].name,
            },
          }
        );

        window.dispatchEvent(updateColumnWidthDynamicLayout);
      }
    };
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(-1);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);

  const columnIndexSelection = (index: number) => {
    setSelectedColumnIndex((prev) => (prev === index ? -1 : index));
  };

  const rowIndexSelection = (index: number) => {
    setSelectedRowIndex((prev) => (prev === index ? -1 : index));
  };

  const openTableModal = async () => {
    setIsOpenModal(true);
    if (!gridOptions.length) {
      let relationalGridOptions = await sfAPI.getSeletectedObjectMetadata(
        selectedObjectName
      );
      relationalGridOptions = relationalGridOptions.fields
        .filter((field) => field.referenceTo && field.referenceTo.length > 0)
        .map((field) => ({
          referenceTo: field.referenceTo[0],
          relationshipName: field.relationshipName || field.name,
          label: field.label,
        }));
      setGridOptions(relationalGridOptions);
    }
  };
  //This method is used to set the default value when user close the modal
  const closeTableModal = () => {
    setIsOpenModal(false);
  };
  /**
   * This method handles header change
   * @param e
   */
  const handleUpdateHeader = (e) => {
    setTableTitleValue(e.target.value);
  };

  const handleDelete = () => {
    const deleteDynamicLayout = new CustomEvent(
      CQAppConstant.EVENT_LISTENER.CQ_DELETE_LAYOUT,
      {
        bubbles: true,
        detail: {
          id: layoutRelationalId.current,
        },
      }
    );

    window.dispatchEvent(deleteDynamicLayout);
  };

  /**
   * This method updates dynamic layout
   * @param apiName
   * @param relationalAPIName
   * @param label
   * @param relationalGrid
   */
  const handleSave = (apiName, relationalAPIName, label, relationalGrid) => {
    if(Object.keys(rootSchema.definitions).map((key)=>{
        if(key === apiName){
          setApiErrorMessage(formBuilderScreenStrings.apiNameDuplicateError);
          return;
        }
    }))
    if (!(apiName.trim().length)) {
      return;
    }
    const updateDynamicLayout = new CustomEvent(
      CQAppConstant.EVENT_LISTENER.CQ_UPDATE_LAYOUT,
      {
        bubbles: true,
        detail: {
          prevRelationalId: layoutRelationalId.current,
          newRelationalId: relationalAPIName,
          prevApiName: layoutId.current,
          newApiName: apiName,
          layoutLabel: label === "" ? tableHeader.title : label,
          relationalGrid,
        },
      }
    );

    window.dispatchEvent(updateDynamicLayout);
  };

  const handleUpdateColumnTitle = (event, columnIndex) => {
    const columnId = getColumns()[columnIndex].name;
    adjustTextareaHeights(event);
    setColumnValue({ ...columnValue, [columnId]: event.target.value });
  };

  const handleUpdateColumnTitleOnBlur = (columnIndex) => {
    if (Object.keys(columnValue).length) {
      const columnId = getColumns()[columnIndex].name;
      const updateColumnTitleDynamicLayout = new CustomEvent(
        CQAppConstant.EVENT_LISTENER.CQ_UPDATE_TITLE_DYNAMIC_LAYOUT,
        {
          bubbles: true,
          detail: {
            relationalId: layoutRelationalId.current,
            value: columnValue[columnId],
            columnId: columnId,
          },
        }
      );
      window.dispatchEvent(updateColumnTitleDynamicLayout);
    }
  };
  
  /*
   * This method dispatches event for removing row
   * @param rowIndex index of the row to add after
  */
  const handleAddRow = (rowIndex) => {
      const addRowDynamicLayout = new CustomEvent(
        CQAppConstant.EVENT_LISTENER.CQ_ADD_ROW_DYNAMIC_LAYOUT,
        {
          bubbles: true,
          detail: {
            relationalId: layoutRelationalId.current,
            index: rowIndex !== -1 ? rowIndex : (getRows().length - 1),
          },
        }
      );
      if(data) {
        const prevData = data[rowIndex];
        data.splice((rowIndex+1), 1, prevData);
        handleChange(path, data);
      }
      window.dispatchEvent(addRowDynamicLayout);
  };

  /*
   * This method dispatches event for removing row
   * @param rowIndex index of the row to add after
  */
  const handleRemoveRow = (rowIndex) => {
    if (rowIndex !== -1) {
      const removeRowDynamicLayout = new CustomEvent(
        CQAppConstant.EVENT_LISTENER.CQ_REMOVE_ROW_DYNAMIC_LAYOUT,
        {
          bubbles: true,
          detail: {
            relationalId: layoutRelationalId.current,
            index: rowIndex,
          },
        }
      );
      if(data) {
        data.splice(rowIndex, 1);
        handleChange(path, data);
      }
      window.dispatchEvent(removeRowDynamicLayout);
    }
  };

  /*
   * This method dispatches event for adding column
   * @param columnIndex index of the column to add after
  */
  const handleAddColumn = (columnIndex) => {
    const addRowDynamicLayout = new CustomEvent(
      CQAppConstant.EVENT_LISTENER.CQ_ADD_COLUMN_DYNAMIC_LAYOUT,
      {
        bubbles: true,
        detail: {
          relationalId: layoutRelationalId.current,
          layoutId: layoutId.current,
          index: columnIndex,
        },
      }
    );
    window.dispatchEvent(addRowDynamicLayout);
  };

  /**
   * this methods increases table width when column width is increased
   * @returns
   */
  const totalWidth = () => {
    if (Object.keys(columnWidths).length) {
      const total: any = Object.values(columnWidths).reduce(
        (sum: any, value: any) => sum + value,
        0
      );
      if (Object.keys(columnWidths).length !== getColumns().length) {
        return "100%";
      }
      return `${total}px`;
    }
  };

  /*
   * This method dispatches event for removing column
   * @param columnIndex index of the column to remove
  */
  const handleRemoveColumn = (columnIndex) => {
    const removeRowDynamicLayout = new CustomEvent(
      CQAppConstant.EVENT_LISTENER.CQ_REMOVE_COLUMN_DYNAMIC_LAYOUT,
      {
        bubbles: true,
        detail: {
          relationalId: layoutRelationalId.current,
          layoutId: layoutId.current,
          index: columnIndex,
        },
      }
    );
    window.dispatchEvent(removeRowDynamicLayout);
  };

  const showDeleteIconForRow = () => {
    return getRows().length > 1 ? true : false;
  };

  const showDeleteIconForColumn = () => {
    return getColumns().length > 1 ? true : false;
  };
  const handleCancelModal = () => {
    setApiErrorMessage("");
  }
  useEffect(() => {
    window.addEventListener('cqremoveerrortext', handleCancelModal);
    return () => {
        window.removeEventListener('cqremoveerrortext', handleCancelModal);
    };
  },[])

  function renderRowsElement(child, index, rowIndex) {
    return config.isFormBuilder ? (
      <Droppable
        isDropDisabled={rowIndex !== 0}
        droppableId={child.options?.id + "-" + rowIndex + "-" + layoutRelationalId.current}
        direction="vertical"
        type="FIELD"
      >
        {(provided, snapshot) => (
          <td
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="slds-p-around_medium"
            style={{
              height: "3rem",
              backgroundColor: snapshot.isDraggingOver
                ? "gainsboro"
                : rowIndex !== 0
                ? "#969696"
                : "inherit",
              border:
                rowIndex === selectedRowIndex
                  ? "1px solid black"
                  : "1px solid #dddbda",
              pointerEvents: rowIndex === 0 ? "auto" : "none",
            }}
          >
            <div key={`${path}-${index}`}>
              <ResolvedJsonFormsDispatch
                renderers={renderers}
                cells={cells}
                visible={visible}
                schema={schema.items}
                uischema={child}
                path={composePaths(path, `${rowIndex}`)}
                enabled={enabled}
              />
            </div>
            {provided.placeholder}
          </td>
        )}
      </Droppable>
    ) : (
      <td
        className="slds-p-around_medium"
        style={{
          height: "3rem",
          border:"1px solid #dddbda",
          backgroundColor: rowIndex === selectedRowIndex ? '#d2eafc':''
        }}
      >
        <div key={`${path}-${index}`}>
          <ResolvedJsonFormsDispatch
            renderers={renderers}
            cells={cells}
            visible={visible}
            schema={schema.items}
            uischema={child}
            path={composePaths(path, `${rowIndex}`)}
            enabled={enabled}
          />
        </div>
      </td>
    );
  }

  function renderEmptyRowsElement(rowIndex, index) {
    return config.isFormBuilder ? (
      <Droppable
        isDropDisabled={rowIndex !== 0}
        droppableId={`grid-${rowIndex}-${index}`}
        direction="vertical"
        type="FIELD"
      >
        {(provided, snapshot) => (
          <td
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="slds-p-around_medium"
            style={{
              flex: "1 1 100%",
              height: "3rem",
              backgroundColor: snapshot.isDraggingOver
                ? "gainsboro"
                : "inherit",
              border:
                rowIndex === selectedRowIndex
                  ? "1px solid black"
                  : "1px solid #dddbda",
            }}
          ></td>
        )}
      </Droppable>
    ) : (
      <td
        className="slds-p-around_medium"
        style={{
          flex: "1 1 100%",
          height: "3rem",
          border: "1px solid black",
        }}
      ></td>
    );
  }

  return (
    <>
      {isLoaded ? (
        <>
          <IconSettings iconPath="/assets/icons">
            <div className="cq-form-table-desktop">
              <div
                className="slds-m-top_small"
                style={{
                  backgroundColor: "white",
                  padding: "0.5rem",
                  border: "1px solid lightgrey",
                  textAlign: "center",
                  overflowX: "auto",
                  overflowY: "hidden",
                }}
              >
                <div className="slds-grid slds-wrap">
                  {!config.isFormBuilder ? (
                    <div className="slds-grid">
                      <button
                        className="slds-m-right_x-small slds-p-around_xx-small slds-grid"
                        disabled={!enabled}
                        onClick={() => handleAddRow(selectedRowIndex)}
                      >
                        <Icon
                          name="add"
                          category="utility"
                          size="xx-small"
                          className="slds-m-right_xxx-small"
                        />
                        <label className="slds-m-top_xxx-small">
                          {strings.addRow}
                        </label>
                      </button>
                      <button
                        className="slds-grid"
                        disabled={!enabled && !showDeleteIconForRow()}
                        onClick={() => handleRemoveRow(selectedRowIndex)}
                      >
                        <Icon
                          style={{
                            pointerEvents: showDeleteIconForRow()
                              ? "all"
                              : "none",
                            cursor: "pointer",
                            fill: "rgb(252, 74, 74)",
                          }}
                          name="delete"
                          category="utility"
                          size="xx-small"
                          className=" slds-m-top_xx-small slds-m-right_xxx-small"
                        />
                        <label className="slds-m-top_xx-small">
                          {strings.deleteRow}
                        </label>
                      </button>
                    </div>
                  ) : null}
                  <div
                    className={
                      config.isFormBuilder
                        ? "slds-col slds-size_3-of-5 slds-text-align_right"
                        : "slds-col slds-size_4-of-5 slds-text-align_center"
                    }
                  >
                    <label style={{ fontSize: "1rem" }}>
                      {validSchema.title}
                    </label>
                  </div>
                  {config.isFormBuilder ? (
                    <div className="slds-col slds-size_2-of-5 slds-text-align_right">
                      <IconSettings iconPath="/assets/icons">
                        <span
                          className="slds-p-horizontal_x-small"
                          onClick={() => openTableModal()}
                        >
                          <Icon
                            style={{ cursor: "pointer", fill: " #0074b3" }}
                            assistiveText={{ label: "edit" }}
                            category="utility"
                            colorVariant="default"
                            name="edit"
                            size="small"
                          />
                        </span>
                      </IconSettings>
                      <IconSettings iconPath="/assets/icons">
                        <span
                          className="slds-p-horizontal_x-small"
                          onClick={handleDelete}
                        >
                          <Icon
                            style={{ cursor: "pointer" }}
                            assistiveText={{ label: "Warning" }}
                            category="utility"
                            colorVariant="error"
                            name="delete"
                            size="small"
                          />
                        </span>
                      </IconSettings>
                    </div>
                  ) : null}
                </div>
                <div className="slds-grid cq-form-table slds-m-top_small slds-size_12-of-12"
                  id={layoutRelationalId.current}
                  style={{width: totalWidth()}}
                >
                  <table
                    className="slds-table slds-scrollable slds-table_cell-buffer slds-table_bordered slds-table_col-bordered slds-size_12-of-12"
                    aria-labelledby="element-with-table-label other-element-with-table-label"
                  >
                    <thead>
                      <tr className="slds-line-height_reset ">
                        <th style={{ width: "2.5rem" }}></th>
                        {getColumns().map((column, index) => (
                          <>
                            <th
                              scope="col"
                              key={index}
                              className="slds-is-resizable slds-cell_action-mode slds-truncate"
                              style={{
                                width: `${columnWidths[column.name]}px`,
                                overflow: "visible",
                                position: "relative",
                                border:
                                  index === selectedColumnIndex
                                    ? "1px solid black"
                                    : "",
                              }}
                            >
                              {index === selectedColumnIndex &&
                              config.isFormBuilder ? (
                                <section
                                  className="slds-is-absolute slds-m-around_auto"
                                  style={{
                                    top: "-1.4rem",
                                    left: "30%",
                                    display: "flex",
                                    gap: "8px",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconSettings iconPath="/assets/icons">
                                    <span
                                      className="slds-p-horizontal_x-small"
                                      onClick={() => handleAddColumn(index)}
                                    >
                                      <Icon
                                        style={{
                                          cursor: "pointer",
                                          fill: " #6a9923",
                                        }}
                                        category="utility"
                                        colorVariant="default"
                                        name="new"
                                        size="x-small"
                                      />
                                    </span>
                                  </IconSettings>
                                  <IconSettings iconPath="/assets/icons">
                                    <span
                                      className="slds-p-horizontal_x-small"
                                      onClick={() => handleRemoveColumn(index)}
                                      style={{
                                        pointerEvents: showDeleteIconForColumn()
                                          ? "all"
                                          : "none",
                                      }}
                                    >
                                      <Icon
                                        style={{
                                          cursor: "pointer",
                                          fill:
                                            showDeleteIconForColumn() &&
                                            " #e3483d",
                                        }}
                                        category="utility"
                                        colorVariant="default"
                                        name="ban"
                                        size="x-small"
                                      />
                                    </span>
                                  </IconSettings>
                                </section>
                              ) : null}
                              <div
                                className="slds-resizable slds-has-flexi-truncate slds-m-vertical_small"
                                title={column.title}
                                style={{ display: "inline-flex" }}
                              >
                                <textarea
                                  rows={1}
                                  className={`textarea-layout-${path
                                    .split(".")
                                    .pop()}`}
                                  style={{
                                    overflow: "hidden",
                                    boxShadow: "none",
                                    border: "none",
                                    outline: "none",
                                    resize: "none",
                                    background: "transparent",
                                  }}
                                  value={
                                    columnValue.hasOwnProperty(
                                      getColumns()[index].name
                                    )
                                      ? columnValue.index
                                      : column.title
                                  }
                                  onChange={(event) => {
                                    handleUpdateColumnTitle(event, index);
                                  }}
                                  onBlur={() =>
                                    handleUpdateColumnTitleOnBlur(index)
                                  }
                                  disabled={!config.isFormBuilder}
                                />
                                {config.isFormBuilder ? (
                                  <>
                                    <input
                                      type="checkbox"
                                      className="slds-m-horizontal_small"
                                      style={{ height: "1.4rem" }}
                                      checked={
                                        index === selectedColumnIndex
                                          ? true
                                          : false
                                      }
                                      onClick={(e) => {
                                        columnIndexSelection(index);
                                      }}
                                    />
                                    <span
                                      className="slds-resizable__handle"
                                      onMouseDown={(e) =>
                                        handleMouseDrag(e, index)
                                      }
                                    >
                                      <span className="slds-resizable__divider"></span>
                                    </span>
                                  </>
                                ) : null}
                              </div>
                            </th>
                          </>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {getRows().map((rows, rowIndex) => (
                        <tr className="slds-hint-parent">
                          <div
                            className="slds-p-around_small slds-grid"
                            style={{ borderTop: "1px solid #dddbda" }}
                          >
                            {config.isFormBuilder ? (
                              rowIndex === selectedRowIndex ? (
                                <section
                                  className="slds-m-around_auto"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <IconSettings iconPath="/assets/icons">
                                    <span
                                      className="slds-p-right_x-small slds-m-bottom_x-small"
                                      onClick={() => handleAddRow(rowIndex)}
                                    >
                                      <Icon
                                        style={{
                                          cursor: "pointer",
                                          fill: " #6a9923",
                                        }}
                                        category="utility"
                                        colorVariant="default"
                                        name="new"
                                        size="x-small"
                                      />
                                    </span>
                                  </IconSettings>
                                  <IconSettings iconPath="/assets/icons">
                                    <span
                                      style={{
                                        pointerEvents: showDeleteIconForRow()
                                          ? "all"
                                          : "none",
                                      }}
                                      className="slds-p-right_x-small"
                                      onClick={() => handleRemoveRow(rowIndex)}
                                    >
                                      <Icon
                                        style={{
                                          cursor: "pointer",
                                          fill:
                                            showDeleteIconForRow() &&
                                            " #e3483d",
                                        }}
                                        category="utility"
                                        colorVariant="default"
                                        name="ban"
                                        size="x-small"
                                      />
                                    </span>
                                  </IconSettings>
                                </section>
                              ) : null
                            ) : null}
                            <input
                              type="checkbox"
                              checked={
                                rowIndex === selectedRowIndex ? true : false
                              }
                              onClick={() => rowIndexSelection(rowIndex)}
                            />
                          </div>
                          {hasNoElements
                            ? rows.map((row, index) =>
                                renderEmptyRowsElement(rowIndex, index)
                              )
                            : uischema.elements.map((child, index) =>
                                renderRowsElement(child, index, rowIndex)
                              )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </IconSettings>
        </>
      ) : null}
      <CQFormBuilderTableModal
        openModal={isOpenModal}
        closeModal={closeTableModal}
        handleUpdateHeader={handleUpdateHeader}
        handleSave={handleSave}
        headerValue={tableTitleValue}
        apiErrorMessage={apiErrorMessage}
        relationalAPIName={
          /^L_Table_\d+__r$/.test(layoutRelationalId.current)
            ? ""
            : layoutRelationalId.current
        }
        title={tableHeader.title}
        validations={"None"}
        fieldApiName={
          /^L_Table_\d+$/.test(layoutId.current) ? "" : layoutId.current
        }
        isColumnModal={false}
        relationalGridOptions={gridOptions}
        selectedRelationalGrid={selectedRelationalGrid.current}
      />
    </>
  );
};

export default withJsonFormsControlProps(CQDynamicLayout);
