/**
 * This class hanldes the GraphQL queries construction and data deserialize to JSON consumble format
 */
export class CQGraphQLProcessor {
  private mainObject: string;
  private recordId: string;
  private mainObjectFields: string[];
  private childFields: { field: string; fields: string[] }[];

  constructor(mainObject: string, recordId: string,mainObjectFields: string[],childFields: { field: string; fields: string[] }[]) {
    this.mainObject = mainObject;
    this.recordId = recordId;
    this.mainObjectFields = mainObjectFields;
    this.childFields = childFields;
  }

  /**
   * This method build
   * @returns string 
   */
  public buildQuery(): string {
    let query = `query {uiapi {query {${this.mainObject}(where: { Id: { eq: \"${this.recordId}\" } }) {edges {node {`;

    // Add main object fields
    for (const field of this.mainObjectFields) {
          query += `
          ${field} { value }`;
    }

    // Add child object fields if they exist
    for (const child of this.childFields) {
      if (child.field.endsWith("__r") && child.fields.length > 0) {
        query += `
          ${child.field} {
            edges {
              node {`;
                query += child.fields.map((f) => `
                ${f} { value }`).join("");
                query += `
              }
            }
          }`;
      }
    }

    query += `
              }
            }
          }
        }
      }
    }`;
    return query;
  }
}


