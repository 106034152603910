import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { useJsonForms, withJsonFormsLayoutProps } from "@jsonforms/react";
import formBuilderScreenStrings from "localizations/formBuilderScreen";

const CQTableEditor = (props) => {
  const ctx = useJsonForms();
  return props.uischema.options.visible === false ? (
    props.uischema.options.visible
  ) : true ? (
    <>
      <div className="MuiGrid-container slds-box slds-m-vertical_xxx-small slds-theme_default">
        <Droppable
          droppableId={`table-drop-${ctx.config?.cqconfig?.sectionId}`}
          type="FIELD"
          direction="vertical"
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
              className="slds-p-around_medium slds-m-horizontal_x-small"
              style={{
                outline: "1px dashed rgba(43,40,38,.35)",
                flex: "1 1 100%",
                height: "3rem",
                backgroundColor: snapshot.isDraggingOver
                  ? "gainsboro"
                  : "inherit",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {formBuilderScreenStrings.dropTable}
            </div>
          )}
        </Droppable>
      </div>
    </>
  ) : (
    <></>
  );
};

export default withJsonFormsLayoutProps(CQTableEditor);
