import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import strings from '../../localizations/homeScreen';
import { uuidv4 } from '../../services/cqIdGenerator';
import CQSubmissionContext from '../../context/CQSubmissionContext';
import CQToast from '../CQToast/CQToast';
import CQCircularProgress from '../CQCircularProgress';

import '@salesforce/canvas-js-sdk';
import AccessManagerContext from '../../context/AccessManagerContext';
import SFAPI from '../../api/sfapi';
import { getNameSpace, getNameSpaceField } from '../../api/namespace';
import { isIndexAvailable } from '../../api/submissiondbapi';
import { CQAutoFillup } from '../../api/CQAutoFillup';
import { CQSubmission } from 'api/CQSubmissionManager';
import { mapDataToSubmission } from 'services/data-mapping.service';


// @ts-ignore
const Sfdc : any = global.Sfdc || {};

/*
 * Launcher component for loading in salesforce 
 */
function CQLaunchFromSF( props) {
    
    const submissionContext = useContext(CQSubmissionContext);
    const accessManager = useContext(AccessManagerContext);

    const sfAPI = new SFAPI().setAccessManager(accessManager);
    
    let history = useHistory();
    const [selectingForm, setSelectingForm] = useState(false);
    const [errors, setErrors] = useState('');

    let { params } = props.match;
    params = {
        formId : params.formId ? decodeURIComponent(params.formId): undefined,
        formName: params.formName ? decodeURIComponent(params.formName): undefined,
        formSubmissionId: params.formSubmissionId ? decodeURIComponent(params.formSubmissionId): undefined,
        formSubmissionStatus: params.formSubmissionStatus? decodeURIComponent(params.formSubmissionStatus): undefined,
        submissionId: params.submissionId? decodeURIComponent(params.submissionId): undefined
    }
    useEffect(() => {

        const launchForm = async()=>{
            setSelectingForm(true);
            try{
                if(params.submissionId){
                    if(params.formSubmissionStatus === 'Scheduled'){
                        let submissions = await submissionContext.getScheduledSubmissions();
                        let submission = submissions.find(sub => sub.Id === params.formSubmissionId);
                        await submissionContext.startSubmission(submission, params.submissionId);
                    }else{
                        let cqAutoFillup = new CQAutoFillup(accessManager);
                        let instanceURL = localStorage.getItem('instanceurl')
                        let data = await sfAPI.syncPendingSubmissions(params.formSubmissionId);
                        // checking JSON data already exist or not 
                        // if already exist then sync otherwise create a new form submission
                        if(typeof data === 'object' && (data.hasOwnProperty('formDef') || data.hasOwnProperty('schema', 'ui'))){
                            if(Object.keys(params).includes('submissionId')){
                                let namespace : string = getNameSpace();
                                let fields : string;
                                let object : string;
                                if(namespace.length > 0){
                                    fields = `Id, Name, ${namespace}__Uniqueness_Constraint__c,${namespace}__Scheduled_Date__c,${namespace}__SQX_Controlled_Document__c,${namespace}__SQX_Controlled_Document__r.Name,${namespace}__Main_Record_Id__c ,${namespace}__Version__c,  Format(LastModifiedDate)`;
                                    object = `${namespace}__CQF_Form_Submission__c`;
                                }else{
                                    fields = `Id, Name, Uniqueness_Constraint__c,Scheduled_Date__c,SQX_Controlled_Document__c,SQX_Controlled_Document__r.Name,Main_Record_Id__c ,Version__c, Format(LastModifiedDate)`;
                                    object = `CQF_Form_Submission__c`;
                                }
                                let formSubmission = await sfAPI.findRelatedRecord(params.formSubmissionId, object, fields);
                                let formDef = await cqAutoFillup.updateContentAccordingFormSubmissionRecordId(formSubmission.records[0]);
                                const submission = await mapDataToSubmission({data: formDef.data || {}, formDef});
                                if(getNameSpaceField(formSubmission.records[0], 'SQX_Controlled_Document__c')){
                                    await submissionContext.upsertSubmission(getNameSpaceField(formSubmission.records[0], 'SQX_Controlled_Document__c'), submission['formDef'], params.formName, params.submissionId, instanceURL, formSubmission.LastModifiedDate, true);
                                }else{
                                   await submissionContext.upsertSubmission(formSubmission.records[0].Id, submission['formDef'], params.formName, params.submissionId, instanceURL, formSubmission.LastModifiedDate, true);
                                }
                            }else{
                                 throw new Error('Invalid Forms Data');
                            }
                        }
                        
                    }
                    history.push(`/form/${params.submissionId}`);
                }else if(params.formId){
                    //TODO: check if there is an open submission
                    let id: string = uuidv4();
                    let data = await submissionContext.getFormDefData(params.formId);
                    if(data != null){
                        let instanceUrl = localStorage.getItem('instanceurl') || '';
                        await submissionContext.upsertSubmission(params.formId, data, params.formName, id, instanceUrl, new Date().toISOString());
                        const queryString = window.location.search;
                        history.push(`/form/${id}${queryString}`);
                    }
                }
            } catch (e) {
                setSelectingForm(false);
                if(e.response && e.response.data){
                    setErrors(strings.serverError + JSON.stringify(e.response.data));
                }else{
                    if(e.message === 'Network Error'){
                        setErrors(strings.networkError);
                    }else{
                        setErrors(JSON.stringify(e.message));
                    }
                }
            }
        }
                
        if(window.location.href.includes('submission-finished')){
            let client = {
                "instanceId" : localStorage.getItem("instanceId") || '',
                "targetOrigin" : localStorage.getItem("targetOrigin") || '',
                "oauthToken" :accessManager.getUserContextSync().token,
                "instanceUrl" : localStorage.getItem("instanceurl") || ''
            }
            let params = (new URL(window.location.href)).searchParams;
            let dataUrl = params.get('dataUrl');
            Sfdc.canvas.client.publish(client,
                {
                    name: 'CQOfflineFormMessage', 
                    dataUrl: dataUrl
                }
            );
            
            return;
        }

        if(window.location.href.includes('submission-closed')) {
            let client = {
                "instanceId" : localStorage.getItem("instanceId") || '',
                "targetOrigin" : localStorage.getItem("targetOrigin") || '',
                "oauthToken" :accessManager.getUserContextSync().token,
                "instanceUrl" : localStorage.getItem("instanceurl") || ''
            }
            let params = (new URL(window.location.href)).searchParams;
            let dataUrl = params.get('dataUrl');
            Sfdc.canvas.client.publish(client,
                {
                    name: 'CQOfflineFormMessageClosed', 
                    dataUrl: dataUrl
                }
            );
            
            return;
        }
        
        launchForm();

    },[]);


    return (
    <>
        <CQCircularProgress isLoading={selectingForm}/>
        Loading... Please wait!
            <CQToast
                variant="error"
                heading={errors}
                duration={10000}
            />
    </>
    );
}

export default CQLaunchFromSF;
