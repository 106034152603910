import { AccessManager } from "api/AccessManager";

/**
 * Method used to set 'edited' option in uischema to true if field is edited on form
 * @param data is the change value of the field in form
 * @param submission is the form submission object
 * @param path is the string that represents the path of the field which is being edited
 * @param uischema is the uischema of the particular field which is edited
 * @returns uischema after updating the edited option
 */
export const setEditedValueinOption = (data: any, submission: any, path: string, uischema: any) => { 
    const originalData: any = submission?.originalData; 

    if(originalData && uischema?.options){ 
        uischema.options.edited = false; 
        const {originalValue, defaultValue} = getOriginalAndDefaultValue(path, uischema, originalData, submission);      

        //checking original value is undefined or not and then allowing 
        //because CQForm might launch with empty values and after making changes it is edited
        //after setting to empty values it should edited=false
        if((defaultValue || data) && defaultValue === data) return uischema;
        if((originalValue || data) && originalValue !== data){ 
            uischema.options.edited = true; 
        }
      }
    return uischema;
}

/**
 * This method returns original and default Value
 * @param path 
 * @param uischema 
 * @param originalData 
 * @param submission 
 * @returns default and original value
 */
const getOriginalAndDefaultValue = (path:any ,uischema:any ,originalData:any ,submission:any, schema?:any) => {
  const accessManager = new AccessManager();
  let dataPath = path;
  if(path.match(/\d+/) && uischema.hasOwnProperty('options')){ //check path represents a child record or not
    if(uischema.options.hasOwnProperty('labelScope')) {
      dataPath =  path+'.'+uischema.options.labelScope;
    }
  }
  const originalValue: any = getValueByPath(originalData, dataPath);
  let defaultValue: any;
  if(submission !== null) {
    defaultValue = getDefaultByPath(submission.formDef.schema, dataPath);
    if(schema && schema?.format === 'date-time' && (defaultValue !== "" && defaultValue !== undefined)) {
      let localTime = new Date(defaultValue).getTime();
      let userTime = new Date(accessManager.getDateTimeStringInUserTimeZone(defaultValue)).getTime();
      let timeDiff = localTime - userTime;
      // this variable used to send datetime value to save it exactly what is shown in UI at field by adjusting time difference.
      let dateTimeToSave = new Date(new Date(defaultValue).getTime() + timeDiff);
      defaultValue = dateTimeToSave.toISOString();
    }
  } 
  return {originalValue, defaultValue};
}

/**
 * checks the default value using path
 * @param schema 
 * @param path 
 * @returns data 
 */
export const getDefaultByPath = (schema, path) => {
  try{
    const keys = path.split('.');
    let current = schema;
    for (const key of keys) {
      if (Number.isInteger(Number(key)) && current.items) {
        current = current.items;
        continue;
      }
      if (!current.properties || !current.properties.hasOwnProperty(key)) {
        return undefined;
      }
      current = current.properties[key];  
      if (current.hasOwnProperty('default')) {
        return current.default;
      }
    }
  }catch(error){
    console.error(error);
  }
  return undefined; // Return undefined if no default found
}

/**
 * This method used to return value by the path and using the form data
 * @param data 
 * @param path 
 * @returns value that gets by using path and data
 */
export const getValueByPath = (data, path)=> {
    const keys = path.split('.');
    let currentValue = data;
    for (const key of keys) {
      if (currentValue && typeof currentValue === 'object') {
        if (Array.isArray(currentValue)) {
          //if currentValue is an array then 'key' will be index number
          const index = parseInt(key);
          currentValue = currentValue[index];
        } else {
          currentValue = currentValue[key];
        }
      } else {
        currentValue = null;
        break;
      }
    }
    return currentValue;
  }

/**
 * Method used to return edited value in uischema's option of a field
 * @param uischema 
 * @returns 
 */
export const getEditedValueFromOption = (uischema:any, data:any, path:any, submission:any, schema?:any)  => { 
  const originalData: any = submission?.originalData;
  if(originalData) {
    const {originalValue, defaultValue} = getOriginalAndDefaultValue(path,uischema,originalData,submission, schema);
    if((originalValue === undefined || originalValue === null) && data === undefined) {
      return false;
    } else if((originalValue || data) && originalValue == data) {
      return false;
    } else if((originalValue !== undefined && originalValue !== null) && defaultValue == data != originalValue) {
      return true;
    } else if((defaultValue || data) && defaultValue == data) {
      return false;
    }
    return true;
  }
  return false;
} 