import React from 'react';
import Checkbox from '@salesforce/design-system-react/components/checkbox';

interface CQCheckboxProps {
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  assistiveText?: string;
}

export default function CQCheckbox({  
    label,
    checked,
    onChange,
    assistiveText = label,
} : CQCheckboxProps){
  return (
    <Checkbox
      assistiveText={{
        label: assistiveText,
      }}
      labels={{
        label: label,
      }}
      checked={checked}
      onChange={onChange}
    />
  );
};