import React, { useEffect, useRef, useState } from "react";
import Modal from "@salesforce/design-system-react/components/modal";
import formBuilderScreenStrings from "localizations/formBuilderScreen";
import Icon from "@salesforce/design-system-react/components/icon";
import Button from '@salesforce/design-system-react/components/button';
import { checkIfRequired } from "./is-field-required";
import CQCheckbox from "./CheckBox";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Checkbox from '@salesforce/design-system-react/components/checkbox';

// incoming props for generic field modal with type
interface CQFormBuilderModalProps {
  isDropdown: boolean;
  isPrimaryFieldModal: boolean;
  isTextfield: boolean;
  isDateField: boolean;
  isStaticField: boolean;
  isBlank: boolean;
  setIsChildDropdown: ()=> void;
  openModal: boolean;
  closeModal: () => void;
  title: string;
  fieldLabel: string;
  defaultValue: string;
  fieldApiName: string;
  handleChange: (evt) => void;
  fillApiName: (evt) => void;
  handleSave: (fieldApiName, selectedOption, validationRuleCode, currentDefaultData, errorMessage, apiName) => void;
  dropdownValues: Array<object>;
  handleDropDownChange: (evt) => void;
  dropdownInput: string;
  saveDropDownValues: () => void;
  removeDropDownValue: (index) => void;
  handleDefaultValue: (evt) => void ;
  validations: any;
  uischema: any;
  schema: any;
  fieldSchema: any;
}

export default function CQGenericFieldModal({
  handleChange,
  fieldLabel,
  fieldApiName,
  isDropdown,
  isTextfield,
  isDateField,
  isStaticField,
  setIsChildDropdown,
  openModal,
  closeModal,
  title,
  fillApiName,
  defaultValue,
  handleSave,
  dropdownValues,
  handleDefaultValue,
  dropdownInput,
  handleDropDownChange,
  saveDropDownValues,
  removeDropDownValue,
  validations,
  isPrimaryFieldModal,
  isBlank,
  uischema,
  schema,
  fieldSchema,
}: CQFormBuilderModalProps) {
  const [currentDropdownValues, setCurrentDropdownValues] = useState(dropdownValues);
  const [currentDefaultValue, setCurrentDefaultValue] = useState(defaultValue);
  const [fieldApiNameInModal, setFieldApiNameInModal] = useState("");
  const isEditedDropdown = useRef(isDropdown);

  const [selectedOption, setSelectedOption] = useState("");
  const [validationRuleCode, setValidationRuleCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isNoneSelected, setIsNoneSelected] = useState(false);
  const [isError, setIsError] = useState("");
  const [richTextContent, setRichTextContent] = useState(uischema.options?.title !== '' ? uischema.options?.title : '');

  // style used for section of modal appears when editing field
  const modalSectionStyle = {
    padding: "1rem",
  };

  // style used for modal text input
  const modalInputStyle = {
    width: "100%",
  };

  // style used for modal dropdown input
  const modalDropDownInputStyle = {
    width: "55%",
  };

  // style used for dropdown label style
  const dropdownLabelStyle = {
    borderBottom: "1px solid black",
    fontSize: "1rem",
  };

  // style used for modal footer section
  const modalFooterStyle = {
    display: "flex",
    justifyContent: "flex-end",
  };

  useEffect(() => {
    setCurrentDropdownValues(dropdownValues);
    isEditedDropdown.current = true;
    setIsChildDropdown();
    setCurrentDefaultValue(defaultValue);
    setFieldApiNameInModal(fieldApiName);
  }, [dropdownValues])

  const handleBlurApiNameChange = (eve : any) => {
    let newFieldApiName = 'CQ_Form_'+eve.target.value.trim().replace(/[\s\W]+/g, '_');
    if(!fieldApiName && fieldApiName !== newFieldApiName){
      setFieldApiNameInModal(newFieldApiName);
      fillApiName(eve);
    }
  }

  const handleApiNameChange = (eve : any) => {
    setFieldApiNameInModal(eve.target.value);
  }
  useEffect(() => {
    setSelectedOption(validations.regexRuleName);
    setValidationRuleCode(validations.pattern);
    setErrorMessage(validations.errorMessage);
    if(validations.regexRuleName === 'none'){
      setIsNoneSelected(true);
    }else{
      setIsNoneSelected(false);
    }
  },[validations])
  
  const patterns = {
    default: {
      regex: '',
      regexRuleName: 'Select an option',
      message: ''
    },
    "none": {
      regex: '',
      regexRuleName: "None",
      message: ""
    },
    "Email Only": {
      regex: "^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$",
      regexRuleName: "Email Address",
      message: "Field Validation : Email Address Only"
    },
    "Phone Number Only": {
      regex: "^\\+?[1-9]\\d{1,14}$",
      regexRuleName: "Phone Number",
      message: "Field Validation : Phone Number Only"
    },
    "Numeric Only": {
      regex: "^\\d*\\.?\\d+$",
      regexRuleName: "Numeric",
      message: "Field Validation : Numeric Only",
    },
    "Alphanumeric Only": {
      regex: "^[a-zA-Z0-9]+$",
      regexRuleName: "Alphanumeric",
      message: "Field Validation : Alphanumeric Only",
    },
    "Alphabetic Only": {
      regex: "^[a-zA-Z]+$",
      regexRuleName: "Alphabetic",
      message: "Field Validation : Alphabetic Only",
    },
    "ZIP Code Only": {
      regex: "^\\d{5}(?:-\\d{4})?$",
      regexRuleName: "ZIP Code",
      message: "Field Validation : ZIP Code Only",
    },
    "URL Only": {
      regex: "^(https?:\\/\\/)?(www\\.)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$",
      regexRuleName: "URL",
      message: "Field Validation : URL Only",
    },
    "Custom (Enter Below)": {
      regex: '',
      regexRuleName: "Custom (Enter Below)",
      message: ""
    }
  }

  // Function to handle pattern selection
 const handlePatternSelection = (e) => {
  const selectedPatternName = e.target.value;
  const pattern = patterns[selectedPatternName];
  setSelectedOption(selectedPatternName);
  setValidationRuleCode(pattern.regex);
  setErrorMessage(pattern.message);
  if(selectedPatternName === 'none'){
    setIsNoneSelected(true);
  }else{
    setIsNoneSelected(false);
  }
};


const handleValidationRuleCodeChange = (e) => {
  setValidationRuleCode(e.target.value); // Update validationRuleCode with the user's input
};

const handleCurrentDefaultValue = (e) => {
  setCurrentDefaultValue(e.target.value);

  if(isValidDateFormat(e.target.value)){
    setIsError("");
  }else{
    setIsError(`Invalid Date Format. ${formBuilderScreenStrings.dateFieldPlaceholder}`);
  }
  if(e.target.value === ""){
    setIsError("");
  }
  handleDefaultValue(e);
}

const handleErrorMessageChange = (e) => {
  setErrorMessage(e.target.value); 
}
const [isDropdownError, setIsDropdownError] = useState('');

//This method is used to check if the default value of dropdown is among the available picklist value
const handleDropdownDefaultValue = (e) => {
  const inputValue = e.target.value;
  setCurrentDefaultValue(inputValue);
  // Check if the input value is empty
  if (!inputValue) {
    setIsDropdownError("");
  } else {
    const isValid = currentDropdownValues.some(value => value['title'] === inputValue);
    if (!isValid) {
      setIsDropdownError(formBuilderScreenStrings.dropdownDefaulPlaceholder);
    } else {
      setIsDropdownError("");
    }
  }
  handleDefaultValue(e);
}


//Function to validate the default value of date field is date or not
const isValidDateFormat = (dateString: string): boolean => {
  const DATE_FORMAT_REGEX = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(\d{4})\s([01]?[0-9]|1[0-2]):([0-5][0-9])\s?(AM|PM)$/;
  return DATE_FORMAT_REGEX.test(dateString);
};

const [isReadOnly, setIsReadOnly] = useState(uischema.options?.readOnly || false);
const [isRequired, setIsRequired] = useState<boolean>(() => checkIfRequired(uischema, schema));
const [isHidden, setIsHidden] = useState(uischema.options?.hideLabel || false);
const [isBackground, setIsBackground] = useState(uischema.options?.headerBackground || false);

const [tempIsReadOnly, setTempIsReadOnly] = useState(uischema.options?.readOnly || false);
const [tempIsRequired, setTempIsRequired] = useState<boolean>(() => checkIfRequired(uischema, schema));
const [tempIsHidden, setTempIsHidden] = useState(uischema.options?.hideLabel || false);



const handleSaveModal = (fieldApiName, selectedOption, validationRuleCode, currentDefaultValue, errorMessage) => {
  // Check if the current field is a date field and if the format is valid
  if (isDateField && currentDefaultValue!== "" && !isValidDateFormat(currentDefaultValue)) {
    return; // Prevent saving if the date format is invalid
  }

  if(isDropdown && isDropdownError !== "") return; //Prevent saving the form when the default value does not match the picklist value for dropdown
  // Update the actual state with the temporary values
  setIsReadOnly(tempIsReadOnly);
  setIsRequired(tempIsRequired);
  setIsHidden(tempIsHidden);

  const updatedUISchema = {
    ...uischema,
    options: {
      ...uischema.options,
      readOnly: tempIsReadOnly,
      hideLabel: tempIsHidden,
      headerBackground: isBackground,
    },
  };

  const makeFieldReadOnly = new CustomEvent("cqreadonlyrequireduischema", {
    bubbles: true,
    detail: {
      uischema: updatedUISchema,
      value: tempIsReadOnly,
      type: formBuilderScreenStrings.readOnlyLabel,
    },
  });
  if (!uischema.options.isReferenceField) {
    document.dispatchEvent(makeFieldReadOnly);
  }

  const makeFieldRequired = new CustomEvent("cqreadonlyrequireduischema", {
    bubbles: true,
    detail: {
      uischema: uischema,
      value: tempIsRequired,
      type: formBuilderScreenStrings.requiredLabel,
    },
  });
  document.dispatchEvent(makeFieldRequired);

  const makeLabelHidden = new CustomEvent("cqreadonlyrequireduischema", {
    bubbles: true,
    detail: {
      uischema: updatedUISchema,
      value: tempIsHidden,
      type: formBuilderScreenStrings.hideLabel,
    },
  });
  document.dispatchEvent(makeLabelHidden);

  // Handle static field case
  if (isStaticField) {
    uischema.options.title = richTextContent;
    const makeStaticHeaderBackground = new CustomEvent("cqreadonlyrequireduischema", {
      bubbles: true,
      detail: {
        uischema: updatedUISchema,
        value: isBackground,
        type: formBuilderScreenStrings.headerField,
      },
    });
    document.dispatchEvent(makeStaticHeaderBackground);
  }

  // Proceed with the original save operation
  handleSave(fieldApiName, selectedOption, validationRuleCode, currentDefaultValue, errorMessage, updatedUISchema.scope.split('/')[2]);
};
const handleCloseModal = () => {
  setIsError("");
  closeModal();
}

const handleDelete = (evt: any) => {
  const deleteFielDUiscema = new CustomEvent("cqdeleteuischema", {
    bubbles: true,
    detail: {
      uischema: uischema,
    },
  });
  document.dispatchEvent(deleteFielDUiscema)
  closeModal();
}

  // Custom toolbar configuration
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

useEffect(() => {
  setRichTextContent(uischema.options?.title !== '' ? uischema.options?.title : '');
  setIsReadOnly(uischema.options?.readOnly || false);
  setIsRequired(checkIfRequired(uischema, schema));
  setIsHidden(uischema.options?.hideLabel || false);
  setTempIsReadOnly(uischema.options?.readOnly || false);
  setTempIsRequired(checkIfRequired(uischema, schema));
  setTempIsHidden(uischema.options?.hideLabel || false);
  setIsBackground(uischema.options?.headerBackground || false);
}, [uischema, schema, fieldSchema]);
  return (
    <Modal
      dismissOnClickOutside={false}
      isOpen={openModal}
      onRequestClose={handleCloseModal}
      title={isStaticField ? formBuilderScreenStrings.staticField : title}
      ariaHideApp={false}
    >
      {isPrimaryFieldModal ? (
        <div>
          <section className="slds-p-around_medium">
              <div className="slds-gutters" style={modalSectionStyle}>
                {isTextfield ? (
                  <>
                    <p className="slds-text-heading_small slds-m-bottom_x-small">
                      {formBuilderScreenStrings.fieldLabel}
                    </p>
                    <input
                      placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                      className="slds-p-around_x-small slds-input"
                      style={modalInputStyle}
                      value={fieldLabel}
                      maxLength={40}
                      onChange={(e) => handleChange(e)}
                      disabled
                    />
                    <br/>
                    <p className="slds-text-heading_small slds-m-bottom_x-small  slds-m-top_x-small">
                      {formBuilderScreenStrings.apiName}
                    </p>
                    <input
                      placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                      className="slds-p-around_x-small slds-input"
                      style={modalInputStyle}
                      maxLength={48} // includes prefix character also ( CQ_Form_ )
                      value={fieldApiNameInModal ? fieldApiNameInModal: fieldApiName}
                      onChange={(e) => handleApiNameChange(e)}
                      disabled
                    />
                    <br/>
                    <div>
                      <p className="slds-text-heading_small slds-m-bottom_x-small  slds-m-top_x-small">
                        {formBuilderScreenStrings.default}
                      </p>
                      <input
                        placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                        className="slds-p-around_x-small slds-input"
                        style={modalInputStyle}
                        maxLength={48} // includes prefix character also ( CQ_Form_ )
                        value={currentDefaultValue}
                        onChange={(e) => handleCurrentDefaultValue(e)}
                      />
                      <br/>
                    </div>
                    <div className="cq-field-checklist">
                      <CQCheckbox label={formBuilderScreenStrings.readOnlyLabel} checked={tempIsReadOnly} onChange={(event) => setTempIsReadOnly(event.target.checked)} />
                      <CQCheckbox label={formBuilderScreenStrings.requiredLabel} checked={tempIsRequired} onChange={(event) => setTempIsRequired(event.target.checked)} />
                      <CQCheckbox label={formBuilderScreenStrings.hideLabel} checked={tempIsHidden} onChange={(event) => setTempIsHidden(event.target.checked)} />
                    </div>
                    <p className="slds-text-heading_small slds-m-bottom_x-small slds-m-top_x-small">
                      {formBuilderScreenStrings.validationRule}
                    </p>
                    <select className="slds-select slds-p-horizontal_x-small" onChange={handlePatternSelection} defaultValue={selectedOption}>
                      {Object.keys(patterns).map((patternName) => (
                        <option key={patternName} value={patternName ? patternName : ""}>
                          {patterns[patternName].regexRuleName}
                        </option>
                      ))}
                    </select> 
                    <br />
                    <div>
                      <p className="slds-text-heading_small slds-m-bottom_x-small slds-m-top_x-small">{formBuilderScreenStrings.validationRuleCode}</p>
                      <input 
                        className="slds-textarea" 
                        value={selectedOption ? validationRuleCode : ""} 
                        onChange={handleValidationRuleCodeChange}
                        style={modalInputStyle}
                        disabled={isNoneSelected}
                      />
                    </div>
                    <div>
                      <p className="slds-text-heading_small slds-m-bottom_x-small slds-m-top_x-small">{formBuilderScreenStrings.validationErrorMessage}</p>
                      <input 
                        className="slds-input" 
                        value={selectedOption ? errorMessage : ""} 
                        style={modalInputStyle}
                        onChange={handleErrorMessageChange} 
                        disabled={isNoneSelected}  
                      />
                    </div>
                  </>
                ) : null}
                {isDropdown && !isTextfield ? (
                  <>
                    <p className="slds-text-heading_small slds-m-bottom_x-small">
                      {formBuilderScreenStrings.fieldLabel}
                    </p>
                    <input
                      placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                      className="slds-p-around_x-small slds-input"
                      style={modalInputStyle}
                      value={fieldLabel}
                      maxLength={40}
                      onChange={(e) => handleChange(e)}
                      disabled
                    />
                    <br/>
                    <p className="slds-text-heading_small slds-m-bottom_x-small  slds-m-top_x-small">
                      {formBuilderScreenStrings.apiName}
                    </p>
                    <input
                      placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                      className="slds-p-around_x-small slds-input"
                      style={modalInputStyle}
                      maxLength={48} // includes prefix character also ( CQ_Form_ )
                      value={fieldApiNameInModal ? fieldApiNameInModal: fieldApiName}
                      onChange={(e) => handleApiNameChange(e)}
                      disabled
                    />
                    <br/>
                    <div>
                      <p className="slds-text-heading_small slds-m-bottom_x-small  slds-m-top_x-small">
                        {formBuilderScreenStrings.default}
                      </p>
                      <input
                        placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                        className="slds-p-around_x-small slds-input"
                        style={modalInputStyle}
                        maxLength={48} // includes prefix character also ( CQ_Form_ )
                        value={currentDefaultValue}
                        onChange={(e) => handleDropdownDefaultValue(e)}
                        disabled={currentDropdownValues.length === 0}
                      />
                      {isDropdownError && (
                        <span style={{color: "red"}}>
                          {isDropdownError}
                        </span>
                      )}
                      <br/>
                    </div>
                    <div className="cq-field-checklist">
                      <CQCheckbox label={formBuilderScreenStrings.readOnlyLabel} checked={tempIsReadOnly} onChange={(event) => setTempIsReadOnly(event.target.checked)} />
                      <CQCheckbox label={formBuilderScreenStrings.requiredLabel} checked={tempIsRequired} onChange={(event) => setTempIsRequired(event.target.checked)} />
                      <CQCheckbox label={formBuilderScreenStrings.hideLabel} checked={tempIsHidden} onChange={(event) => setTempIsHidden(event.target.checked)} />
                    </div>
                    <div className="slds-m-top_large">
                      <p className="slds-m-bottom_small slds-text-heading_small">
                        {formBuilderScreenStrings.picklistValues}
                      </p>
                      <section className="slds-grid">
                        <input
                          className="slds-col slds-size_10-of-12 slds-box slds-m-right_xx-small"
                          placeholder={formBuilderScreenStrings.picklistPlaceholder}
                          value={dropdownInput}
                          onChange={(e) => handleDropDownChange(e)}
                        />
                        <button
                          className="slds-col slds-size_2-of-12 slds-button slds-button_neutral"
                          onClick={saveDropDownValues}
                        >
                          {`+ ${formBuilderScreenStrings.add}`}
                        </button>
                      </section>
                      <section className="slds-box slds-m-top_small">
                          {currentDropdownValues.map((value, index) => (
                          <section className="slds-grid slds-m-bottom_small">
                            <label
                              className="slds-col slds-size_10-of-12 slds-m-right_small"
                              style={dropdownLabelStyle}
                            >
                              {value["title"]}
                            </label>
                            <span
                              onClick={() => removeDropDownValue(index)}
                              className="slds-col slds-size_1-of-12  slds-button"
                            >
                              <Icon
                                assistiveText={{ label: "delete" }}
                                category="utility"
                                colorVariant="default"
                                name="delete"
                                size="x-small"
                                className="$font-size-11"
                              />
                            </span>
                          </section>
                        ))}
                      </section>
                    </div>
                  </>
                ): null}
                {isDateField && !isTextfield ? (
                  <>
                    <p className="slds-text-heading_small slds-m-bottom_x-small">
                      {formBuilderScreenStrings.fieldLabel}
                    </p>
                    <input
                      placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                      className="slds-p-around_x-small slds-input"
                      style={modalInputStyle}
                      value={fieldLabel}
                      maxLength={40}
                      onChange={(e) => handleChange(e)}
                      disabled
                    />
                    <br/>
                    <p className="slds-text-heading_small slds-m-bottom_x-small  slds-m-top_x-small">
                      {formBuilderScreenStrings.apiName}
                    </p>
                    <input
                      placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                      className="slds-p-around_x-small slds-input"
                      style={modalInputStyle}
                      maxLength={48} // includes prefix character also ( CQ_Form_ )
                      value={fieldApiNameInModal ? fieldApiNameInModal: fieldApiName}
                      onChange={(e) => handleApiNameChange(e)}
                      disabled
                    />
                    <br/>
                    <p className="slds-text-heading_small slds-m-bottom_x-small  slds-m-top_x-small">
                      {formBuilderScreenStrings.default}
                    </p>
                    <input
                      placeholder={formBuilderScreenStrings.dateFieldPlaceholder}
                      className="slds-p-around_x-small slds-input"
                      style={modalInputStyle}
                      maxLength={48} // includes prefix character also ( CQ_Form_ )
                      value={currentDefaultValue}
                      onChange={(e) => handleCurrentDefaultValue(e)}
                    />
                    {isError && (
                      <span style={{color: "red"}}>
                        {isError}
                      </span>
                    )}
                    <br/>
                    <div className="cq-field-checklist">
                      <CQCheckbox label={formBuilderScreenStrings.readOnlyLabel} checked={tempIsReadOnly} onChange={(event) => setTempIsReadOnly(event.target.checked)} />
                      <CQCheckbox label={formBuilderScreenStrings.requiredLabel} checked={tempIsRequired} onChange={(event) => setTempIsRequired(event.target.checked)} />
                      <CQCheckbox label={formBuilderScreenStrings.hideLabel} checked={tempIsHidden} onChange={(event) => setTempIsHidden(event.target.checked)} />
                    </div>
                  </>
                ) : null}
                <div
                  className="slds-grid slds-m-top_large"
                  style={modalFooterStyle}
                > 
                    {/* Left-aligned Delete button */}
                    <div className="slds-col">
                      <Button
                        className="cq-generic-modal-delete-button"
                        style={{ height: 30 }}
                        iconCategory="utility"
                        iconName="delete"
                        label="Delete"
                        iconPosition="right"
                        iconSize="medium"
                        variant="destructive"
                        onClick={(evt) => handleDelete(evt)}
                      />
                    </div>

                    {/* Right-aligned Cancel and Save buttons */}
                    <div className="slds-col slds-grid slds-grid_align-end">
                      <button
                        className="slds-button slds-button_neutral"
                        onClick={handleCloseModal}
                      >
                        {formBuilderScreenStrings.cancel}
                      </button>
                      <button
                        className="slds-button slds-button_brand slds-m-left_small"
                        onClick={() =>
                          handleSaveModal(
                            fieldApiNameInModal,
                            selectedOption,
                            validationRuleCode,
                            currentDefaultValue,
                            errorMessage
                          )
                        }
                      >
                        {formBuilderScreenStrings.save}
                      </button>
                    </div>
                </div>
              </div>
            </section>
        </div>
      ): (
        <div>
          {!isEditedDropdown.current ? ( // load modal respectively by checking if editing field is a dropdown 
            <section className="slds-p-around_medium">
              <div className="slds-gutters" style={modalSectionStyle}>
                <p className="slds-text-heading_small slds-m-bottom_x-small">
                  {formBuilderScreenStrings.fieldLabel}
                </p>
                <input
                  placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                  className="slds-p-around_x-small"
                  style={modalInputStyle}
                  value={fieldLabel}
                  maxLength={40}
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => handleBlurApiNameChange(e)}
    
                />
                <p className="slds-text-heading_small slds-m-bottom_x-small">
                  {formBuilderScreenStrings.apiName}
                </p>
                <input
                  placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                  className="slds-p-around_x-small"
                  style={modalInputStyle}
                  maxLength={48} // includes prefix character also ( CQ_Form_ )
                  value={fieldApiNameInModal ? fieldApiNameInModal: fieldApiName}
                  onChange={(e) => handleApiNameChange(e)}
                />
                <div
                  className="slds-grid slds-m-top_large"
                  style={modalFooterStyle}
                >
                    {/* Left-aligned Delete button */}
                    <div className="slds-col">
                      <Button
                        style={{ height: 30 }}
                        className="cq-generic-modal-delete-button"
                        iconCategory="utility"
                        iconName="delete"
                        label="Delete"
                        iconPosition="right"
                        iconSize="medium"
                        variant="destructive"
                        onClick={(evt) => handleDelete(evt)}
                      />
                    </div>

                    {/* Right-aligned Cancel and Save buttons */}
                    <div className="slds-col slds-grid slds-grid_align-end">
                      <button
                        className="slds-button slds-button_neutral"
                        onClick={handleCloseModal}
                      >
                        {formBuilderScreenStrings.cancel}
                      </button>
                      <button
                        className="slds-button slds-button_brand slds-m-left_small"
                        onClick={() =>
                          handleSaveModal(
                            fieldApiNameInModal,
                            selectedOption,
                            validationRuleCode,
                            currentDefaultValue,
                            errorMessage
                          )
                        }
                      >
                        {formBuilderScreenStrings.save}
                      </button>
                    </div>
                </div>
              </div>
            </section>
          ) : (
            <section className="slds-p-around_medium">
              <div className="slds-gutters" style={modalSectionStyle}>
                {isTextfield ? (
                  <>
                    <div>
                      <p className="slds-text-heading_small slds-m-bottom_x-small">
                        {formBuilderScreenStrings.fieldLabel}
                      </p>
                      <input
                        placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                        className="slds-p-around_x-small slds-input"
                        style={modalInputStyle}
                        value={fieldLabel}
                        maxLength={40}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleBlurApiNameChange(e)}
                      />
                      <br/>
                      <p className="slds-text-heading_small slds-m-bottom_x-small  slds-m-top_x-small">
                        {formBuilderScreenStrings.apiName}
                      </p>
                      <input
                        placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                        className="slds-p-around_x-small slds-input"
                        style={modalInputStyle}
                        maxLength={48} // includes prefix character also ( CQ_Form_ )
                        value={fieldApiNameInModal ? fieldApiNameInModal: fieldApiName}
                        onChange={(e) => handleApiNameChange(e)}
                      />
                      {!isBlank && <>
                        <p className="slds-text-heading_small slds-m-bottom_x-small  slds-m-top_x-small">
                          {formBuilderScreenStrings.default}
                        </p>
                        <input
                          placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                          className="slds-p-around_x-small slds-input"
                          style={modalInputStyle}
                          maxLength={48} // includes prefix character also ( CQ_Form_ )
                          value={currentDefaultValue}
                          onChange={(e) => handleCurrentDefaultValue(e)}
                        />
                        <div className="cq-field-checklist">
                          <CQCheckbox label={formBuilderScreenStrings.readOnlyLabel} checked={tempIsReadOnly} onChange={(event) => setTempIsReadOnly(event.target.checked)} />
                          <CQCheckbox label={formBuilderScreenStrings.requiredLabel} checked={tempIsRequired} onChange={(event) => setTempIsRequired(event.target.checked)} />
                          <CQCheckbox label={formBuilderScreenStrings.hideLabel} checked={tempIsHidden} onChange={(event) => setTempIsHidden(event.target.checked)} />
                        </div>
                        <p className="slds-text-heading_small slds-m-bottom_x-small slds-m-top_x-small">
                        {formBuilderScreenStrings.validationRule}
                        </p>
                        <select className="slds-select slds-p-horizontal_x-small" onChange={handlePatternSelection} defaultValue={selectedOption}>
                          {Object.keys(patterns).map((patternName) => (
                            <option key={patternName} value={patternName ? patternName : ""}>
                              {patterns[patternName].regexRuleName}
                            </option>
                          ))}
                        </select> 
                        <br />
                        <div>
                          <p className="slds-text-heading_small slds-m-bottom_x-small slds-m-top_x-small">{formBuilderScreenStrings.validationRuleCode}</p>
                          <input 
                            className="slds-textarea" 
                            value={selectedOption ? validationRuleCode : ""} 
                            onChange={handleValidationRuleCodeChange}
                            style={modalInputStyle}
                            disabled={isNoneSelected}
                          />
                        </div>
                        <div>
                          <p className="slds-text-heading_small slds-m-bottom_x-small slds-m-top_x-small">{formBuilderScreenStrings.validationErrorMessage}</p>
                          <input 
                            className="slds-input" 
                            value={selectedOption ? errorMessage : ""} 
                            style={modalInputStyle}
                            onChange={handleErrorMessageChange} 
                            disabled={isNoneSelected}  
                          />
                        </div>
                      </>}
                  </div>  
                  </>) : null
                }
                {isStaticField && !isTextfield ? (
                  <>
                    <ReactQuill
                      theme="snow"
                      value={richTextContent}
                      onChange={(value) => {
                        setRichTextContent(value);
                        handleChange({ target: { value } });
                      }}
                      placeholder={formBuilderScreenStrings.generalRichTextPlaceholder}
                      style={modalInputStyle}
                      modules={modules}
                    />
                    <div className="cq-field-checklist">
                      <Checkbox labels={{ label: formBuilderScreenStrings.readOnlyLabel, }} checked={true} onChange={(event) => setTempIsReadOnly(event.target.checked)} disabled/>
                    </div>
                    <div className="cq-field-checklist">
                      <CQCheckbox label={formBuilderScreenStrings.headerField} checked={isBackground} onChange={(event) => setIsBackground(event.target.checked)} />
                    </div>
                  </>
                ) : null}
                {isDropdown && !isTextfield ?  (
                <div>
                  <div>
                      <p className="slds-text-heading_small slds-m-bottom_x-small">
                        {formBuilderScreenStrings.fieldLabel}
                      </p>
                      <input
                        placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                        className="slds-p-around_x-small slds-input"
                        style={modalInputStyle}
                        value={fieldLabel}
                        maxLength={40}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleBlurApiNameChange(e)}
                      />
                      <br/>
                      <p className="slds-text-heading_small slds-m-bottom_x-small  slds-m-top_x-small">
                        {formBuilderScreenStrings.apiName}
                      </p>
                      <input
                        placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                        className="slds-p-around_x-small slds-input"
                        style={modalInputStyle}
                        maxLength={48} // includes prefix character also ( CQ_Form_ )
                        value={fieldApiNameInModal ? fieldApiNameInModal: fieldApiName}
                        onChange={(e) => handleApiNameChange(e)}
                      />
                      <p className="slds-text-heading_small slds-m-bottom_x-small  slds-m-top_x-small">
                        {formBuilderScreenStrings.default}
                      </p>
                      <input
                        placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                        className="slds-p-around_x-small slds-input"
                        style={modalInputStyle}
                        maxLength={48} // includes prefix character also ( CQ_Form_ )
                        value={currentDefaultValue}
                        onChange={(e) => handleDropdownDefaultValue(e)}
                        disabled={currentDropdownValues.length === 0}
                      />
                      <div className="cq-field-checklist">
                        <CQCheckbox label={formBuilderScreenStrings.readOnlyLabel} checked={tempIsReadOnly} onChange={(event) => setTempIsReadOnly(event.target.checked)} />
                        <CQCheckbox label={formBuilderScreenStrings.requiredLabel} checked={tempIsRequired} onChange={(event) => setTempIsRequired(event.target.checked)} />
                        <CQCheckbox label={formBuilderScreenStrings.hideLabel} checked={tempIsHidden} onChange={(event) => setTempIsHidden(event.target.checked)} />
                      </div>
                      {isDropdownError && (
                        <span style={{color: "red"}}>
                          {isDropdownError}
                        </span>
                      )}
                      <br/>
                  </div>
                  <div className="slds-m-top_large">
                  <p className="slds-m-bottom_small slds-text-heading_small">
                    {formBuilderScreenStrings.picklistValues}
                  </p>
                  <section className="slds-grid">
                    <input
                      className="slds-col slds-size_10-of-12 slds-box slds-m-right_xx-small"
                      placeholder={formBuilderScreenStrings.picklistPlaceholder}
                      value={dropdownInput}
                      onChange={(e) => handleDropDownChange(e)}
                    />
                    <button
                      className="slds-col slds-size_2-of-12 slds-button slds-button_neutral"
                      onClick={saveDropDownValues}
                    >
                      {`+ ${formBuilderScreenStrings.add}`}
                    </button>
                  </section>
                  <section className="slds-box slds-m-top_small">
                      {currentDropdownValues.map((value, index) => (
                      <section className="slds-grid slds-m-bottom_small">
                        <label
                          className="slds-col slds-size_10-of-12 slds-m-right_small"
                          style={dropdownLabelStyle}
                        >
                          {value["title"]}
                        </label>
                        <span
                          onClick={() => removeDropDownValue(index)}
                          className="slds-col slds-size_1-of-12  slds-button"
                        >
                          <Icon
                            assistiveText={{ label: "delete" }}
                            category="utility"
                            colorVariant="default"
                            name="delete"
                            size="x-small"
                            className="$font-size-11"
                          />
                        </span>
                      </section>
                    ))}
                  </section>
                  </div>
                </div>
                ):''}
                {isDateField && !isTextfield ?  (
                  <div>
                    <p className="slds-text-heading_small slds-m-bottom_x-small">
                      {formBuilderScreenStrings.fieldLabel}
                    </p>
                    <input
                      placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                      className="slds-p-around_x-small slds-input"
                      style={modalInputStyle}
                      value={fieldLabel}
                      maxLength={40}
                      onChange={(e) => handleChange(e)}
                      onBlur={(e) => handleBlurApiNameChange(e)}
                    />
                    <br/>
                    <p className="slds-text-heading_small slds-m-bottom_x-small  slds-m-top_x-small">
                      {formBuilderScreenStrings.apiName}
                    </p>
                    <input
                      placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                      className="slds-p-around_x-small slds-input"
                      style={modalInputStyle}
                      maxLength={48} // includes prefix character also ( CQ_Form_ )
                      value={fieldApiNameInModal ? fieldApiNameInModal: fieldApiName}
                      onChange={(e) => handleApiNameChange(e)}
                    />
                    <p className="slds-text-heading_small slds-m-bottom_x-small  slds-m-top_x-small">
                      {formBuilderScreenStrings.default}
                    </p>
                    <input
                      placeholder={formBuilderScreenStrings.dateFieldPlaceholder}
                      className="slds-p-around_x-small slds-input"
                      style={modalInputStyle}
                      maxLength={48} // includes prefix character also ( CQ_Form_ )
                      value={currentDefaultValue}
                      onChange={(e) => handleCurrentDefaultValue(e)}
                    />
                    {isError && (
                      <span style={{color: "red"}}>
                        {isError}
                      </span>
                    )}
                    <br/>
                    <div className="cq-field-checklist">
                      <CQCheckbox label={formBuilderScreenStrings.readOnlyLabel} checked={tempIsReadOnly} onChange={(event) => setTempIsReadOnly(event.target.checked)} />
                      <CQCheckbox label={formBuilderScreenStrings.requiredLabel} checked={tempIsRequired} onChange={(event) => setTempIsRequired(event.target.checked)} />
                      <CQCheckbox label={formBuilderScreenStrings.hideLabel} checked={tempIsHidden} onChange={(event) => setTempIsHidden(event.target.checked)} />
                    </div>
                  </div>
                ): null}
                <div
                  className="slds-grid slds-m-top_large"
                  style={modalFooterStyle}
                >
                    {/* Left-aligned Delete button */}
                    <div className="slds-col">
                      <Button
                        style={{ height: 30 }}
                        className="cq-generic-modal-delete-button"
                        iconCategory="utility"
                        iconName="delete"
                        label="Delete"
                        iconPosition="right"
                        iconSize="medium"
                        variant="destructive"
                        onClick={(evt) => handleDelete(evt)}
                      />
                    </div>

                    {/* Right-aligned Cancel and Save buttons */}
                    <div className="slds-col slds-grid slds-grid_align-end">
                      <button
                        className="slds-button slds-button_neutral"
                        onClick={handleCloseModal}
                      >
                        {formBuilderScreenStrings.cancel}
                      </button>
                      <button
                        className="slds-button slds-button_brand slds-m-left_small"
                        onClick={() =>
                          handleSaveModal(
                            fieldApiNameInModal,
                            selectedOption,
                            validationRuleCode,
                            currentDefaultValue,
                            errorMessage
                          )
                        }
                      >
                        {formBuilderScreenStrings.save}
                      </button>
                    </div>
                </div>
              </div>
            </section>
          )}
        </div>
      )}
      
    </Modal>
  );
}
