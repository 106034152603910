/**
 * 
 * @param uischema This method check wheter the field is required or not
 * @param schema 
 * @returns 
 */

export const checkIfRequired = (uischema: any, schema: any): boolean => {
    let currentUischema = uischema;
    let fieldName: string = currentUischema?.scope?.split("/").pop();
    let GENERAL = "General";
    let requiredFieldsList: string[] = [];
    let key: string = "";
  
    if (uischema?.scope?.includes(GENERAL)) {
      key = GENERAL;
    } else if (schema?.hasOwnProperty('properties')) {
      key = Object.keys(schema?.properties)[0];
    }
  
    requiredFieldsList = schema?.properties[key]?.required || [];
    return requiredFieldsList?.includes(fieldName || "");
  };